import WrapFormat, { WrapFormatProps } from '../../common/Format/WrapFormat';
import { Space, Tag } from 'antd';
import { BackupsIcon, SyncIcon } from '../../common/icons/icons';
import SpaceDescriptions from '../../common/Layout/SpaceDescriptions';
import TypographyText from '../../common/TypographyText';
import AppDivider from '../../common/AppDivider';
import React, { Fragment } from 'react';
import CcBackup, { CcBackupMethod } from '../../services/models/CcBackup';
import BackupStatusFormat, {
    getBackupStatusFormatType,
} from './BackupStatusFormat';
import CcCluster from '../../services/models/CcCluster';
import ClusterFormat from '../Clusters/ClusterFormat';
import BooleanStatusFormat from '@severalnines/bar-frontend-components/build/lib/Format/BooleanStatusFormat';
import { CloseCircleOutlined } from '@ant-design/icons';
import ByteFormat from '@severalnines/bar-frontend-components/build/lib/Format/ByteFormat';
import AppDateFormat from '../../common/AppDateFormat';
import SpaceWide from '../../common/SpaceWide';
import { AppIconSize } from '../../common/icons/AppIcon';
import ContentWrapper from '../../common/Layout/ContentWrapper';

export default BackupFormat;
export type BackupFormatProps = WrapFormatProps & {
    children?: React.ReactNode;
    backup: CcBackup;
    cluster?: CcCluster;
    shortBackupName?: boolean;
    wide?: boolean;
    description?: React.ReactNode;
};

function BackupFormat({
    children,
    backup,
    cluster,
    shortBackupName = false,
    size,
    wide,
    description,
    popoverProps,
    ...rest
}: BackupFormatProps) {
    const content =
        size === 'xlarge' ? (
            <SpaceWide className="BackupFormat" align={'start'} size={15}>
                <ContentWrapper size="small">
                    <BackupsIcon size={AppIconSize.medium} />
                </ContentWrapper>
                <SpaceWide direction="vertical">
                    <SpaceDescriptions.Item
                        size={0}
                        label="Method"
                        colon={false}
                        labelStrong={true}
                        direction="vertical"
                    >
                        {backup.getMethod()}
                    </SpaceDescriptions.Item>
                    <SpaceWide size={30}>
                        <SpaceDescriptions.Item
                            size={0}
                            label="Size"
                            colon={false}
                            labelStrong={true}
                            direction="vertical"
                        >
                            <ByteFormat val={backup?.getSize()} />
                        </SpaceDescriptions.Item>
                        <SpaceDescriptions.Item
                            size={0}
                            label="Created"
                            colon={false}
                            labelStrong={true}
                            direction="vertical"
                        >
                            <AppDateFormat fromNow>
                                {backup.getCreated()
                                    ? new Date(backup.getCreated())
                                    : undefined}
                            </AppDateFormat>
                        </SpaceDescriptions.Item>
                    </SpaceWide>
                    <SpaceDescriptions.Item
                        label="Backup host"
                        colon={false}
                        labelStrong={true}
                        direction="vertical"
                    >
                        {backup.getBackupHost()}
                    </SpaceDescriptions.Item>
                </SpaceWide>
            </SpaceWide>
        ) : size === 'large' ? (
            <Space className="BackupFormat" size={15}>
                <BackupsIcon />
                <Space direction="vertical" size={0}>
                    <TypographyText strong style={{ flexGrow: 1 }}>
                        Backup ID: {backup.getId()}
                    </TypographyText>
                    {description || (
                        <span>
                            Created{' '}
                            <AppDateFormat fromNow>
                                {backup.getCreated()
                                    ? new Date(backup.getCreated())
                                    : undefined}
                            </AppDateFormat>
                        </span>
                    )}
                </Space>
            </Space>
        ) : shortBackupName ? (
            <Space size={5}>
                {`#${backup.getId()} (${getBackupMethodText(
                    backup.getMethod()
                )})`}
                -<AppDateFormat fromNow>{backup.getCreated()}</AppDateFormat>
            </Space>
        ) : (
            <Space className="BackupFormat" size={5}>
                {getBackupDesc(backup)} -
                <AppDateFormat>{backup.getCreated()}</AppDateFormat>
            </Space>
        );

    const includeDatabases = backup.getConfig()?.includeDatabases;
    return (
        <WrapFormat
            size={size}
            popoverStatus={getBackupStatusFormatType(backup.getStatus())}
            popoverProps={{
                title: (
                    <Space>
                        <SyncIcon /> Backup Information
                    </Space>
                ),
                destroyTooltipOnHide: true,
                ...popoverProps,
            }}
            popoverContent={
                <Space direction="vertical" size={20} style={{ width: '100%' }}>
                    <SpaceDescriptions direction="horizontal" size={30}>
                        <SpaceDescriptions.Item
                            label="ID"
                            colon={false}
                            direction="vertical"
                        >
                            {backup.getId()}
                        </SpaceDescriptions.Item>
                        <SpaceDescriptions.Item
                            label="Method"
                            direction="vertical"
                            colon={false}
                        >
                            {backup.getMethod()}
                        </SpaceDescriptions.Item>
                        {cluster ? (
                            <SpaceDescriptions.Item
                                label="Cluster Name"
                                direction="vertical"
                                colon={false}
                            >
                                <TypographyText nowrap>
                                    <ClusterFormat cluster={cluster} showId />
                                </TypographyText>
                            </SpaceDescriptions.Item>
                        ) : null}
                        <SpaceDescriptions.Item
                            label="Status"
                            direction="vertical"
                            colon={false}
                        >
                            <BackupStatusFormat backup={backup} />
                        </SpaceDescriptions.Item>
                    </SpaceDescriptions>
                    <AppDivider />
                    <SpaceDescriptions direction="horizontal" size={30}>
                        <SpaceDescriptions.Item
                            label="Encrypted"
                            colon={false}
                            direction="vertical"
                        >
                            <BooleanStatusFormat
                                showIcon={true}
                                contentOn="Yes"
                                contentOff="No"
                                statusOff="default"
                                iconOff={<CloseCircleOutlined />}
                                booleanVar={backup.getEncrypted()}
                            />
                        </SpaceDescriptions.Item>
                        <SpaceDescriptions.Item
                            label="Verified"
                            colon={false}
                            direction="vertical"
                        >
                            <BooleanStatusFormat
                                showIcon={true}
                                contentOn="Yes"
                                contentOff="No"
                                statusOff="default"
                                iconOff={<CloseCircleOutlined />}
                                booleanVar={backup.getVerified()}
                            />
                        </SpaceDescriptions.Item>
                        <SpaceDescriptions.Item
                            label="PITR"
                            colon={false}
                            direction="vertical"
                        >
                            <BooleanStatusFormat
                                showIcon={true}
                                contentOn="Yes"
                                contentOff="No"
                                statusOff="default"
                                iconOff={<CloseCircleOutlined />}
                                booleanVar={backup.getUseForPitr()}
                            />
                        </SpaceDescriptions.Item>
                        <SpaceDescriptions.Item
                            label="Size"
                            colon={false}
                            direction="vertical"
                        >
                            <ByteFormat val={backup?.getSize()} />
                        </SpaceDescriptions.Item>
                        {backup.getCreated() ? (
                            <SpaceDescriptions.Item
                                label="Created"
                                colon={false}
                                direction="vertical"
                            >
                                <AppDateFormat fromNow>
                                    {new Date(backup.getCreated())}
                                </AppDateFormat>
                            </SpaceDescriptions.Item>
                        ) : null}
                    </SpaceDescriptions>
                    <AppDivider />
                    <SpaceDescriptions direction="horizontal" size={30}>
                        <SpaceDescriptions.Item
                            label="Backup Host"
                            colon={false}
                            direction="vertical"
                        >
                            {backup.getBackupHost()}
                        </SpaceDescriptions.Item>
                        <SpaceDescriptions.Item
                            label="Storage Host"
                            colon={false}
                            direction="vertical"
                        >
                            {backup.getStorageHost()}
                        </SpaceDescriptions.Item>
                        <SpaceDescriptions.Item
                            label="Storage Location"
                            colon={false}
                            direction="vertical"
                        >
                            {backup.getRootDir()}
                        </SpaceDescriptions.Item>
                    </SpaceDescriptions>
                    <AppDivider />
                    <SpaceDescriptions direction="horizontal" size={30}>
                        <SpaceDescriptions.Item
                            label="Databases"
                            colon={false}
                            direction="vertical"
                        >
                            {(includeDatabases &&
                                Array.isArray(includeDatabases) &&
                                includeDatabases?.map((db) => (
                                    <Tag key={db}>{db}</Tag>
                                ))) || <Tag key="all">All Databases</Tag>}
                        </SpaceDescriptions.Item>
                    </SpaceDescriptions>
                </Space>
            }
            contentProps={{
                ...rest.contentProps,
                ...(wide
                    ? { style: { width: '100%', ...rest.contentProps?.style } }
                    : {}),
            }}
            {...rest}
        >
            {children || content}
        </WrapFormat>
    );
}

// @todo sync with getBackupMethodName from BackupMethodSelect
export function getBackupMethodText(backupMethod: CcBackupMethod) {
    switch (backupMethod) {
        case CcBackupMethod.MYSQLDUMP:
            return 'mysqldump';
        case CcBackupMethod.XTRABACKUP:
            return 'xtrabackup';
        case CcBackupMethod.XTRABACKUP_FULL:
            return 'xtrabackupfull';
        case CcBackupMethod.XTRABACKUP_INCR:
            return 'xtrabackupincr';
        case CcBackupMethod.MARIABACKUP:
            return 'mariabackup';
        case CcBackupMethod.MARIABACKUP_FULL:
            return 'mariabackupfull';
        case CcBackupMethod.MARIABACKUP_INCR:
            return 'mariabackupincr';
        case CcBackupMethod.NDB:
            return 'ndb';
        case CcBackupMethod.PGDUMPALL:
            return 'pgdumpall';
        case CcBackupMethod.PGDUMP:
            return 'pgdump';
        case CcBackupMethod.PGBASEBACKUP:
            return 'pg_basebackup';
        case CcBackupMethod.PGBACKREST_FULL:
            return 'pgbackrestfull';
        case CcBackupMethod.PGBACKREST_DIFF:
            return 'pgbackrestdiff';
        case CcBackupMethod.PGBACKREST_INC:
            return 'pgbackrestincr';
        case CcBackupMethod.MONGODUMP:
            return 'mongodump';
        case CcBackupMethod.MONGODB_CONSISTENT:
            return 'mongodb-consistent-backup';
        case CcBackupMethod.MONGODB_PERCONA:
            return 'percona-backup-mongodb';
        case CcBackupMethod.RDB:
            return 'rdb';
        case CcBackupMethod.AOF:
            return 'aof';
        case CcBackupMethod.REDIS:
            return 'redis';
        case CcBackupMethod.MSSQL_FULL:
            return 'mssqlfull';
        case CcBackupMethod.MSSQL_DIFF:
            return 'mssqldiff';
        case CcBackupMethod.MSSQL_LOG:
            return 'mssqllog';
        case CcBackupMethod.ELASTIC_SNAPSHOT:
            return 'elastic_snapshot';
        case CcBackupMethod.PROXYSQL:
            return 'ProxySQL';
        default:
            return 'N/A';
    }
}

export function getBackupDesc(backup: CcBackup) {
    return `${
        backup.getMethod() === CcBackupMethod.XTRABACKUP_INCR ||
        backup.getMethod() === CcBackupMethod.MARIABACKUP_INCR
            ? 'Incremental'
            : 'Backup set'
    } ${backup.getId()} (${getBackupMethodText(backup.getMethod())})`;
}
