import { FormInstance } from 'antd/lib/form';
import { Alert, Col, Form, Input, Row } from 'antd';
import ClusterNodeSelect from '../../../../../common/DataEntry/ClusterNodeSelect';
import SpaceWide from '../../../../../common/SpaceWide';
import React, { useEffect, useMemo } from 'react';
import CcCluster, {
    CcClusterTechnology,
    CcClusterType,
} from '../../../../../services/models/CcCluster';
import { BackupRestoreWizardFormValues } from '../BackupRestoreWizardForm';
import BackupRestoreSettingsPosrtgresFormFields from './BackupRestoreSettingsPostgresFormFields';
import BackupRestoreSettingsMySqlFormFields from './BackupRestoreSettingsMySqlFormFields';
import FormValueListener from '../../../../../common/DataEntry/FormValueListener';
import TypographyText from '../../../../../common/TypographyText';
import CcBackupStorageLocation from '../../../../../services/models/CcBackupStorageLocation';

export default BackupRestoreSettingsForm;
type BackupRestoreSettingsFormProps = {
    cluster: CcCluster;
    form: FormInstance<BackupRestoreWizardFormValues>;
    verificationAvailable?: boolean;
    onlyPrimary?: boolean;
};

function BackupRestoreSettingsForm({
    cluster,
    form,
    verificationAvailable = true,
    onlyPrimary = true,
}: BackupRestoreSettingsFormProps) {
    const {
        backup,
        backupLocation,
    }: BackupRestoreWizardFormValues = form.getFieldsValue(true);

    const settingsFields = useMemo(() => {
        switch (cluster.clusterType) {
            case CcClusterType.TYPE_GALERA:
            case CcClusterType.TYPE_REPLICATION:
            case CcClusterType.TYPE_GROUP_REPLICATION:
                return (
                    <BackupRestoreSettingsMySqlFormFields
                        cluster={cluster}
                        form={form}
                    />
                );
            case CcClusterType.TYPE_POSTGRESQL:
                return (
                    <BackupRestoreSettingsPosrtgresFormFields
                        cluster={cluster}
                        form={form}
                    />
                );
        }
    }, [cluster]);

    const storageLocation: CcBackupStorageLocation = (backupLocation &&
        backup?.getLocation(backupLocation)) as CcBackupStorageLocation;

    const isCloud = storageLocation?.isTypeCloud();

    /**
     *
     */
    const lockRestoreOnPrimary =
        onlyPrimary &&
        [
            CcClusterTechnology.TECHNOLOGY_POSTGRESQL,
            CcClusterTechnology.TECHNOLOGY_REDIS,
        ].includes(cluster.getTechnology());

    const backupHostNode = useMemo(() => {
        return cluster.nodes.find(
            (node) =>
                node.hostname === backup?.getBackupHost() &&
                (onlyPrimary ? node.isPrimary() : true)
        );
    }, [cluster, backup, onlyPrimary]);

    useEffect(() => {
        if (backupHostNode && !form.getFieldValue('node')) {
            form.setFieldsValue({
                node: backupHostNode?.getHostWithPort(),
            });
        } else if (lockRestoreOnPrimary) {
            const node = cluster.primaryNode;
            form.setFieldsValue({
                node: node?.getHostWithPort(),
            });
        }
    }, [cluster, backupHostNode, lockRestoreOnPrimary]);

    const handlePITRChange = (current: boolean, previous: boolean) => {
        if (current && backupHostNode) {
            form.setFieldsValue({
                node: backupHostNode?.getHostWithPort(),
                bootstrapCluster: true,
            });
        }
    };

    return (
        <>
            <FormValueListener name={['pitr']} onChange={handlePITRChange} />
            <Row gutter={[24, 0]}>
                <Col xs={24} sm={24} md={12}>
                    <Form.Item shouldUpdate={true} noStyle={true}>
                        {() => {
                            const pitr = form.getFieldValue('pitr');
                            return (
                                <Form.Item
                                    name="node"
                                    label={<span>Restore backup on</span>}
                                    extra={
                                        pitr && (
                                            <TypographyText muted={true}>
                                                PITR is only supported for
                                                backups created on the same host
                                                on which it is going to be
                                                restored.
                                            </TypographyText>
                                        )
                                    }
                                >
                                    <ClusterNodeSelect
                                        cluster={cluster}
                                        primaryCandidatesOnly={true}
                                        selectFirst={true}
                                        databaseNodes={true}
                                        disabled={
                                            lockRestoreOnPrimary || !!pitr
                                        }
                                    />
                                </Form.Item>
                            );
                        }}
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                    <Form.Item
                        name="tmpDirectory"
                        label={<span>Temporary directory</span>}
                    >
                        <Input placeholder="Enter temporary directory" />
                    </Form.Item>
                </Col>
                {isCloud && (
                    <Col xs={24} sm={24} md={12}>
                        <Form.Item
                            name="downloadDirectory"
                            label={<span>Download backup to</span>}
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Please enter absolute directory path',
                                },
                            ]}
                        >
                            <Input placeholder="Enter absolute directory path" />
                        </Form.Item>
                    </Col>
                )}
            </Row>
            <SpaceWide direction="vertical">
                {settingsFields}
                {verificationAvailable && !backup?.getVerified() && (
                    <Alert
                        showIcon={true}
                        type={'warning'}
                        description={
                            <span>
                                Restoring a <b>non-verified</b> backup may cause
                                server failures and unexpected results.
                            </span>
                        }
                    />
                )}
            </SpaceWide>
        </>
    );
}
