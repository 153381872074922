import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row, Space, Typography } from 'antd';
import Switch from '../../../common/DataEntry/Switch';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import NodeTree, { NodeTreeDataNode } from '../../../common/NodeTree';
import { DeleteOutlined } from '@ant-design/icons';
import { StatusFormatStatus } from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import FormItemInlineSwitch from '../../../common/DataEntry/FormItemInlineSwitch';
import DisableFirewallField from '../../../common/Form/Fields/DisableFirewallField';
import InstallSoftwareSwitch from '../../../common/Form/Fields/InstallSoftwareSwitch';
import DisableSelinuxField from '../../../common/Form/Fields/DisableSelinuxField';
import { FormInstance } from 'antd/lib/form';
import { BackupFormFieldsType } from '../Config/BackupFormConfigurator';
import SshCheckableNodesInput from '../../../common/DataEntry/SshCheckeableNodesInput';
import CcCluster from '../../../services/models/CcCluster';
import CmonHost from '../../../services/cmon/models/CmonHost';
import { TopologyItem } from '../../../components/Topology/TopologyItem';

export default BackupVerifyForm;

export type BackupVerifyFormProps = {
    form: FormInstance<BackupFormFieldsType>;
    cluster: CcCluster;
};
function BackupVerifyForm({ form, cluster }: BackupVerifyFormProps) {
    const verificationHostField = form.getFieldValue('verificationHostField');
    const verifyBackupHost = form.getFieldValue('verificationHost');

    if (verificationHostField && !verifyBackupHost) {
        form.setFieldsValue({
            verificationHost: [
                new TopologyItem({
                    key: 'primary0',
                    title: verificationHostField,
                    description: 'Node',
                    extraData: new CmonHost({
                        hostname: verificationHostField,
                    }),
                    status: StatusFormatStatus.success,
                }),
            ],
        });
    }

    const handleItemAdded = (item: TopologyItem) => {
        form.setFieldsValue({
            verificationHost: [new TopologyItem(item)],
        });
    };

    return (
        <div className="BackupVerifyForm">
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <h3>Verify Settings</h3>
                </Col>
                <Col span={24}>
                    <Form.Item name="verificationHost">
                        <SshCheckableNodesInput
                            clusterId={cluster?.clusterId}
                            singleNode={true}
                            formProps={{
                                primaryTitle: (
                                    <Space>
                                        <span>Restore Backup On</span>
                                        <InfoIcon info="Enter a FQDN or IP Address. This host will be used to restore and verify that backup set." />
                                    </Space>
                                ),
                                primaryRequired: true,
                            }}
                            onItemAdd={handleItemAdded}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Space>
                <Space />
            </Space>
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <h3>Additional Settings</h3>
                </Col>
                <Col xs={24} sm={24} md={12}>
                    <InstallSoftwareSwitch
                        name="verificationInstallSoftware"
                        label="Install database software"
                        noMargin
                    />
                </Col>
                <Col xs={24} sm={24} md={12}>
                    <DisableFirewallField name="verificationDisableFirewall" />
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col xs={24} sm={24} md={12}>
                    <FormItemInlineSwitch
                        justify={true}
                        name="verificationShutdownAfter"
                        label="Shut down the server after backup restored"
                        valuePropName="checked"
                    >
                        <Switch />
                    </FormItemInlineSwitch>
                </Col>
                <Col xs={24} sm={24} md={12}>
                    <DisableSelinuxField name="verificationDisableSELinux" />
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col xs={24} sm={24} md={16}>
                    <Form.Item
                        name="verificationStartIn"
                        label={
                            <Space>
                                Start backup verification (after completion) in:
                            </Space>
                        }
                        extra={
                            'You can set verification up to 24 hours after completion'
                        }
                    >
                        <Input placeholder="0 hours" addonAfter="hour(s)" />
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
}

export function getBackupVerifyValidate(form: FormInstance) {
    return [
        () => {
            const verificationHost: TopologyItem[] =
                form.getFieldValue('verificationHost') || [];
            if (verificationHost.length === 0) {
                throw new Error('Please add host to restore backup on!');
            }
            if (
                verificationHost.find(
                    (item) => item.status !== StatusFormatStatus.success
                )
            ) {
                throw new Error('Node should be reachable');
            }
        },
    ];
}
