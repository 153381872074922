import React, { useContext, useEffect, useMemo, useState } from 'react';
import WizardFormConfiguration, {
    WizardFormConfigurationProps,
} from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardFormConfiguration';
import BackupConfigurationForm, {
    getBackupConfigurationValidate,
} from './BackupConfigurationForm';
import BackupWizardFormSummary from './BackupWizardFormSummary';
import BackupStorageForm, {
    getBackupStorageValidate,
} from './BackupStorageForm';
import CcCluster from '../../../services/models/CcCluster';
import BackupAdvancedForm from './BackupAdvancedForm';
import BackupCloudForm, { getBackupCloudValidate } from './BackupCloudForm';
import BackupFormConfigurator, {
    BackupFormFieldsType,
    BackupFormWizardStep,
} from '../Config/BackupFormConfigurator';
import { useForm } from 'antd/lib/form/Form';
import BackupDefaultFieldsConfig from '../Config/BackupDefaultFieldsConfig';
import useBackupWizardSteps from './useBackupWizardSteps';
import { ClusterConfigContext } from '../../Clusters/Config/ClusterConfigContext';
import CcBackupSchedule from '../../../services/models/CcBackupSchedule';
import {
    AdvancedBackupSettingsIcon,
    CloudIcon,
    ScheduleBackupIcon,
    SettingsIcon,
    StorageIcon,
    VerifyBackupIcon,
} from '../../../common/icons/icons';
import { AppIconSize } from '../../../common/icons/AppIcon';
import BackupScheduleCronInputForm from './BackupScheduleCronInputForm';
import BackupVerifyForm, { getBackupVerifyValidate } from './BackupVerifyForm';
import CmonJobsService from '../../../services/cmon/CmonJobsService';
import {
    NotifyType,
    notifyError,
    notifyOperationSuccess,
} from '../../Notifications/uiNotification';
import { addNewRunningJob } from '../../../appReducer';
import { CcJobStatus } from '../../../services/models/CcJob';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import CronFormat from '@severalnines/bar-frontend-components/build/lib/Format/CronFormat';
import { useDispatch } from 'react-redux';
import CmonJobService from '../../../services/cmon/CmonJobsService';
import BackupJobConfig from '../Config/BackupJobConfig';
import useClusterList from '../../Clusters/useClusterList';
import { useNotificationContext } from '../../Notifications/NotificationProvider';
import moment from 'moment';
import { useDebugContext } from '../../../common/Debug';

export type BackupWizardFormProps = Omit<
    WizardFormConfigurationProps,
    'form' | 'steps' | 'onSubmit'
> & {
    cluster?: CcCluster;
    schedule?: CcBackupSchedule;
    saveMode?: boolean;
    isSchedule?: boolean;
    onSuccess?: () => void;
};

const BackupWizardForm = ({
    cluster,
    schedule,
    saveMode = false,
    isSchedule = false,
    onSuccess,
    ...rest
}: BackupWizardFormProps) => {
    const { log } = useDebugContext();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { list: clusters } = useClusterList({ fromStore: true });
    const { configGroupedLoading, configGrouped, setClusterId } = useContext(
        ClusterConfigContext
    );
    const [cloudLoading, setCloudLoading] = useState<boolean>(false);
    const [selectedCluster, setSelectedCluster] = useState<
        CcCluster | undefined
    >(cluster);
    const { notifyJobCreationSuccess } = useNotificationContext();
    const [form] = useForm<BackupFormFieldsType>();
    const { availableSteps, valuesUpdated } = useBackupWizardSteps({ form });
    const defaultCluster = useMemo(() => {
        return (
            cluster ||
            // set cluster from schedule as default
            (schedule &&
                clusters?.find(
                    (c) => c.clusterId && c.clusterId === schedule.clusterId
                ))
        );
    }, [cluster, schedule, clusters]);

    useEffect(() => {
        if (!selectedCluster) {
            if (defaultCluster && !selectedCluster) {
                setSelectedCluster(defaultCluster);
            } else if (clusters && clusters.length > 0) {
                // if no default cluster, set first cluster selected
                setSelectedCluster(clusters?.[0]);
            }
        }
    }, [defaultCluster, clusters]);

    useEffect(() => {
        if (selectedCluster) {
            setClusterId(selectedCluster?.clusterId);
        }
    }, [selectedCluster]);

    const configurator = useMemo(() => {
        const commonDefaults = BackupDefaultFieldsConfig.common();
        const clusterDefaults = selectedCluster
            ? BackupDefaultFieldsConfig.cluster(selectedCluster)
            : {};
        const configDefaults = configGrouped?.backup
            ? BackupDefaultFieldsConfig.clusterConfig(configGrouped.backup)
            : {};
        const scheduleDefaults = schedule
            ? BackupDefaultFieldsConfig.schedule(schedule)
            : {};

        const defaults = {
            ...commonDefaults,
            ...clusterDefaults,
            ...configDefaults,
            ...scheduleDefaults,
        };
        form.setFieldsValue({
            ...defaults,
            clusterType: selectedCluster?.clusterType,
        });
        valuesUpdated(defaults);
        return new BackupFormConfigurator({
            form,
            defaults,
            clusterType: selectedCluster?.clusterType,
        });
    }, [form, selectedCluster, configGrouped]);

    const handleSubmit = async () => {
        if (isSchedule) {
            if (saveMode) {
                await updateSchedule();
            } else {
                await createSchedule();
            }
        } else {
            await createBackup();
        }
    };

    const handleClusterSelect = (cluster?: CcCluster) => {
        setSelectedCluster(cluster);
    };

    const handleValuesChange = (values: BackupFormFieldsType) => {
        valuesUpdated(values);
    };

    const createBackup = async (): Promise<boolean> => {
        const formFields = configurator.getAvailableFields();
        try {
            if (!formFields.clusterId) {
                throw new Error('Cluster is not set');
            }
            setLoading(true);
            const { job } = await CmonJobsService.createBackupJobInstance(
                formFields.clusterId,
                {
                    job_data: BackupJobConfig.fieldsToJobData(formFields),
                },
                {
                    job: {
                        title: 'Creating Backup',
                    },
                }
            );
            setLoading(false);

            notifyJobCreationSuccess({
                title: 'Backup started successfully!',
                content: (
                    <>
                        Your backup has started and will take some time to
                        finish up.
                    </>
                ),
                job,
            });
            onSuccess?.();

            dispatch(addNewRunningJob(job));

            return true;
        } catch (err) {
            setLoading(false);
            notifyError({ size: 'large', content: err.message });
        }
        return false;
    };

    const createSchedule = async (): Promise<boolean> => {
        const formFields = configurator.getAvailableFields();
        try {
            if (!formFields.clusterId) {
                throw new Error('Cluster is not set');
            }
            setLoading(true);
            await CmonJobsService.createJobInstance({
                cluster_id: formFields.clusterId,
                job: {
                    class_name: 'CmonJobInstance',
                    status: CcJobStatus.SCHEDULED,
                    title: formFields.scheduleName || 'Backup Schedule',
                    job_spec: JSON.stringify({
                        command: CmonJobInstanceCommand.BACKUP,
                        job_data: BackupJobConfig.fieldsToJobData(formFields),
                    }),
                    recurrence: `TZ=${moment()
                        .tz(fixGMT(formFields.timezone))
                        .format('Z')} ${formFields.scheduleCron}`,
                },
            });
            setLoading(false);
            notifyOperationSuccess({
                type: NotifyType.TOAST,
                title: 'Schedule created successfully!',
                content: (
                    <span>
                        Backup will start{' '}
                        <CronFormat>{formFields.scheduleCron}</CronFormat>.
                    </span>
                ),
            });
            onSuccess?.();

            return true;
        } catch (err) {
            setLoading(false);
            notifyError({ size: 'large', content: err.message });
            log.error(err);
        }
        return false;
    };

    function fixGMT(input) {
        if (input.includes('Etc/GMT')) {
            return input.includes('+')
                ? input.replace('+', '-')
                : input.replace('-', '+');
        }
        return input;
    }

    const updateSchedule = async (): Promise<boolean> => {
        const formFields = configurator.getAvailableFields();
        try {
            if (!schedule?.clusterId) {
                throw new Error('Cluster is not set');
            }
            setLoading(true);
            await CmonJobService.updateJobInstance({
                cluster_id: schedule.clusterId,
                job: {
                    class_name: 'CmonJobInstance',
                    status: schedule.status,
                    title: formFields.scheduleName || 'Backup Schedule',
                    job_id: schedule.jobId,
                    job_spec: {
                        command: CmonJobInstanceCommand.BACKUP,
                        job_data: BackupJobConfig.fieldsToJobData(formFields),
                    },
                    recurrence: `TZ=${moment()
                        .tz(fixGMT(formFields.timezone))
                        .format('Z')} ${
                        formFields.scheduleCron || schedule.recurrence
                    }`,
                },
            });
            setLoading(false);
            notifyOperationSuccess({
                type: NotifyType.TOAST,
                title: 'Schedule updated successfully!',
                content: 'You can see your updates in schedules list.',
            });
            onSuccess?.();
            return true;
        } catch (err) {
            setLoading(false);
            notifyError({ size: 'large', content: err.message });
        }
        return false;
    };

    const steps = useMemo(() => {
        const configuration = (
            <WizardFormConfiguration.Step
                key={BackupFormWizardStep.CONFIGURATION}
                title="Configuration"
                subTitle=" "
                validate={getBackupConfigurationValidate(form)}
                icon={
                    saveMode ? <SettingsIcon size={AppIconSize.medium} /> : null
                }
                hasRequiredFields={true}
            >
                <BackupConfigurationForm
                    cluster={selectedCluster}
                    onClusterSelect={handleClusterSelect}
                    form={form}
                    configurator={configurator}
                    clusterPreselected={!!defaultCluster}
                />
            </WizardFormConfiguration.Step>
        );

        const advanced = availableSteps.includes(
            BackupFormWizardStep.ADVANCED
        ) && (
            <WizardFormConfiguration.Step
                key={BackupFormWizardStep.ADVANCED}
                title="Advanced settings"
                subTitle=" "
                icon={
                    saveMode ? (
                        <AdvancedBackupSettingsIcon size={AppIconSize.medium} />
                    ) : null
                }
                hasRequiredFields={true}
            >
                <BackupAdvancedForm
                    cluster={selectedCluster}
                    form={form}
                    configurator={configurator}
                />
            </WizardFormConfiguration.Step>
        );

        const verify = availableSteps.includes(BackupFormWizardStep.VERIFY) && (
            <WizardFormConfiguration.Step
                key={BackupFormWizardStep.VERIFY}
                title="Verify backup"
                subTitle=" "
                icon={
                    saveMode ? (
                        <VerifyBackupIcon size={AppIconSize.medium} />
                    ) : null
                }
                validate={getBackupVerifyValidate(form)}
                hasRequiredFields={true}
            >
                <BackupVerifyForm form={form} cluster={selectedCluster} />
            </WizardFormConfiguration.Step>
        );

        const cloud = availableSteps.includes(BackupFormWizardStep.CLOUD) && (
            <WizardFormConfiguration.Step
                key={BackupFormWizardStep.CLOUD}
                title="Cloud storage"
                subTitle=" "
                icon={saveMode ? <CloudIcon size={AppIconSize.medium} /> : null}
                hasRequiredFields={true}
                validate={getBackupCloudValidate()}
                ignoreOutOfDateError={true}
            >
                <BackupCloudForm
                    form={form}
                    onLoadingChange={(loading) => {
                        setCloudLoading(loading);
                    }}
                />
            </WizardFormConfiguration.Step>
        );

        const storage = availableSteps.includes(
            BackupFormWizardStep.STORAGE
        ) && (
            <WizardFormConfiguration.Step
                key={BackupFormWizardStep.STORAGE}
                title={cloud ? 'Local storage' : 'Storage'}
                subTitle=" "
                validate={getBackupStorageValidate()}
                icon={
                    saveMode ? <StorageIcon size={AppIconSize.medium} /> : null
                }
                hasRequiredFields={true}
            >
                <BackupStorageForm
                    cluster={selectedCluster}
                    configurator={configurator}
                />
            </WizardFormConfiguration.Step>
        );
        const schedule = isSchedule && (
            <WizardFormConfiguration.Step
                key={BackupFormWizardStep.SCHEDULE}
                title="Schedule"
                subTitle=" "
                icon={
                    saveMode ? (
                        <ScheduleBackupIcon size={AppIconSize.medium} />
                    ) : null
                }
                hasRequiredFields={true}
                validate={['scheduleCron']}
            >
                <BackupScheduleCronInputForm form={form} />
            </WizardFormConfiguration.Step>
        );

        const preview = !saveMode && (
            <WizardFormConfiguration.Step
                key={BackupFormWizardStep.PREVIEW}
                title="Preview"
                subTitle=" "
            >
                <BackupWizardFormSummary configurator={configurator} />
            </WizardFormConfiguration.Step>
        );

        return [
            configuration,
            advanced,
            verify,
            storage,
            cloud,
            schedule,
            preview,
        ].filter((step) => !!step) as [];
    }, [form, availableSteps, saveMode]);

    return (
        <WizardFormConfiguration
            loading={loading || configGroupedLoading || cloudLoading}
            onValuesChange={handleValuesChange}
            initialValues={{
                isSchedule,
                clusterType: selectedCluster?.clusterType,
            }}
            saveMode={saveMode}
            form={form}
            onSubmit={handleSubmit}
            steps={steps}
            doneButtonText={!saveMode && 'Create'}
            {...rest}
        />
    );
};

export default BackupWizardForm;
