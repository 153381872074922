import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import './ClusterList.less';
import {
    AutoRecoveryIcon,
    ClustersIcon,
    DashboardsIcon,
    NodesIcon,
} from '../../common/icons/icons';
import CcCluster from '../../services/models/CcCluster';
import ResponsiveTableFooter from '@severalnines/bar-frontend-components/build/lib/DataDisplay/ResponsiveTableFooter';
import useClusterList from './useClusterList';
import { ResponsiveContext } from '@severalnines/bar-frontend-components/build/lib/Layout/Responsive';
import ClusterStateFormat, {
    getClusterStateFormatType,
} from './ClusterStateFormat';
import ClusterTypeFormat, { getClusterTypeIcon } from './ClusterTypeFormat';
import { Space } from 'antd';
import { getSortAlphabeticFn, getSortNumberFn } from '../../common/sorting';
import CcNode from '../../services/models/CcNode';
import TypographyText from '../../common/TypographyText';
import SpaceDescriptions from '../../common/Layout/SpaceDescriptions';
import ClusterActionsMenu from './Actions/ClusterActionsMenu';
import AppLink from '../../common/AppLink';
import ClusterLoadChart from './ClusterLoadChart';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import AppDivider from '../../common/AppDivider';
import { arrayDifference } from '../../common/filtering';
import ClusterReplicationFormat from './ClusterReplicationFormat';
import ClustersEmpty from './ClustersEmpty';
import LicenseSpaceWrapper from '../License/LicenseSpaceWrapper';
import JobProgress from '../Jobs/JobProgress';
import { CmonJobInstanceCommand } from '../../services/cmon/models/CmonJobInstance';
import AppPageWrapper from '../../common/Layout/AppPageWrapper';
import TableWithJobRow from '../../common/DataDisplay/TableWithJobRow';
import UserAclManageCluster from '../User/UserAclManageCluster';
import classNames from 'classnames';
import useTableFilter from '../../common/hooks/useTableFilter';
import { ArrayParam } from 'use-query-params';
import ClusterFilter, { ClusterFilterParams } from './ClusterFilter';
import merge from 'deepmerge';
import TagsList from '../../common/DataDisplay/TagsList';
import FilteredTableEmpty from '../../common/General/FilteredTableEmpty';
import NodeListInline from '../Nodes/NodeListInline';
import ClusterToggleAutoRecoveryButton from './Actions/ClusterToggleAutoRecoveryButton';
import ClusterToggleNodeAutoRecoveryButton from './Actions/ClusterToggleNodeAutoRecoveryButton';

export default ClusterList;

export type ClusterListProps = {};

function ClusterList({}: ClusterListProps) {
    const {
        filterParams,
        handleTableChange,
        addFilterParams,
        resetFilters,
    } = useTableFilter({
        params: {
            tags: ArrayParam,
            clusterTypes: ArrayParam,
            nodeTypes: ArrayParam,
            state: ArrayParam,
        },
        defaultParams: {
            sort: 'id',
            page: 1,
        },
    });
    const { responsive } = useContext(ResponsiveContext);
    const {
        list: clusters,
        loading: loadingClusters,
        refresh: refreshClusters,
        filter: filterClusters,
        page,
        pageSize,
        total,
        totalFiltered,
    } = useClusterList({
        name: 'cluster-list',
        pageSize: 20,
        useCache: false,
    });
    useEffect(() => {
        (async () => {
            await refreshClusters({
                autoRefresh: 10000,
            });
        })();
    }, []);

    useEffect(() => {
        const filters = [];
        if (filterParams.tags) {
            filters.push((cluster: CcCluster) => {
                return filterParams.tags.some((tag: string) =>
                    cluster.tagList.includes(tag)
                );
            });
        }
        if (filterParams.nodeTypes) {
            filters.push((cluster: CcCluster) => {
                return filterParams.nodeTypes.some((nodeType: string) =>
                    cluster.nodes.some(
                        (node: CcNode) => node.nodetype === nodeType
                    )
                );
            });
        }
        if (filterParams.clusterTypes) {
            filters.push((cluster: CcCluster) => {
                return filterParams.clusterTypes.includes(cluster.clusterType);
            });
        }
        if (filterParams.state) {
            filters.push((cluster: CcCluster) => {
                return filterParams.state.includes(
                    getClusterStateFormatType(cluster.state)
                );
            });
        }
        filterClusters?.({
            page: pagination.current,
            order: getSorter(filterParams),
            filters,
        });
    }, [filterParams]);

    const getSorter = (filterParams: any) => {
        switch (filterParams.sort) {
            case 'id':
                return getSortNumberFn(filterParams.order, (x) => x.clusterId);
            case 'type':
                return getSortAlphabeticFn(
                    filterParams.order,
                    (x) => x.clusterType
                );
            case 'name':
                return getSortAlphabeticFn(
                    filterParams.order,
                    (x) => x.clusterName
                );
        }
        return null;
    };

    const handleActionPerformed = async () => {
        await refreshClusters();
    };

    const columns = useMemo(
        () => [
            {
                title: '',
                key: 'details',
                render: (cluster: CcCluster) => (
                    <div className="ClusterList_detail-cell">
                        <Space size={20}>
                            <div>
                                {getClusterTypeIcon(
                                    cluster.clusterType,
                                    cluster.vendor,
                                    {
                                        size: 46,
                                    }
                                )}
                            </div>
                            <SpaceDescriptions
                                title={
                                    cluster.deploymentJobId ? (
                                        <TypographyText
                                            style={{ maxWidth: 250 }}
                                            ellipsis={{
                                                tooltip: cluster?.clusterName,
                                            }}
                                        >
                                            {cluster?.clusterName}
                                        </TypographyText>
                                    ) : (
                                        <AppLink
                                            to={`/clusters/${cluster.clusterId}/dashboard`}
                                            hoverable
                                        >
                                            <TypographyText
                                                style={{ maxWidth: 250 }}
                                                ellipsis={{
                                                    tooltip:
                                                        cluster?.clusterName,
                                                }}
                                            >
                                                {cluster?.clusterName}
                                            </TypographyText>
                                        </AppLink>
                                    )
                                }
                                direction="vertical"
                                nowrapTitle={true}
                            >
                                <TypographyText muted nowrap>
                                    {cluster.clusterId ? (
                                        <span>
                                            <span>ID: {cluster.clusterId}</span>{' '}
                                            |{' '}
                                        </span>
                                    ) : undefined}
                                    <ClusterTypeFormat
                                        type={cluster.clusterType}
                                    />{' '}
                                    {cluster.version}
                                </TypographyText>

                                {cluster.tagList.length > 0 ? (
                                    <TagsList
                                        tags={cluster.tagList}
                                        className="ClusterList_detail-cell_tags"
                                    />
                                ) : undefined}

                                {!cluster.deploymentJobId ? (
                                    <ClusterStateFormat
                                        cluster={cluster}
                                        showMaintenance
                                    />
                                ) : undefined}
                            </SpaceDescriptions>
                        </Space>
                    </div>
                ),
            },
            {
                title: '',
                key: 'nodes',
                render: (cluster: CcCluster) => {
                    return (
                        <SpaceDescriptions
                            title={
                                <AppLink
                                    to={`/clusters/${cluster.clusterId}/nodes`}
                                    hoverable
                                >
                                    <Space>
                                        <NodesIcon /> Nodes
                                    </Space>
                                </AppLink>
                            }
                            direction="vertical"
                        >
                            <NodeListInline cluster={cluster} />
                        </SpaceDescriptions>
                    );
                },
                hideWithJob: true,
            },
            {
                title: '',
                key: 'autorecovery',
                render: (cluster: CcCluster) => (
                    <SpaceDescriptions
                        direction="vertical"
                        wide={true}
                        title={
                            <Space>
                                <AutoRecoveryIcon />
                                <TypographyText ellipsis={true}>
                                    Auto recovery
                                </TypographyText>
                            </Space>
                        }
                    >
                        <SpaceDescriptions.Item label="Cluster">
                            <ClusterToggleAutoRecoveryButton
                                cluster={cluster}
                                showLabel={false}
                            />
                        </SpaceDescriptions.Item>
                        <SpaceDescriptions.Item label="Node">
                            <ClusterToggleNodeAutoRecoveryButton
                                cluster={cluster}
                                showLabel={false}
                            />
                        </SpaceDescriptions.Item>
                    </SpaceDescriptions>
                ),
                hideWithJob: true,
            },
            {
                key: 'load',
                render: (cluster: CcCluster) =>
                    cluster.deploymentJobId ? (
                        <JobProgress
                            jobId={cluster.deploymentJobId}
                            command={CmonJobInstanceCommand.CREATE_CLUSTER}
                            size="small"
                            alignTitle="left"
                        />
                    ) : (
                        <SpaceDescriptions
                            direction="vertical"
                            wide
                            title={
                                cluster.isMonitoringEnabled() ? (
                                    <Space>
                                        <TypographyText ellipsis={true}>
                                            <Space>
                                                <AppLink
                                                    to={`/clusters/${cluster.clusterId}/dashboard?slug=cluster-overview`}
                                                    hoverable
                                                >
                                                    <Space>
                                                        <DashboardsIcon /> Load
                                                    </Space>
                                                </AppLink>
                                                <TypographyText muted>
                                                    <InfoIcon info="Showing last 5 minutes of max load average" />
                                                </TypographyText>
                                            </Space>
                                        </TypographyText>
                                    </Space>
                                ) : null
                            }
                        >
                            <SpaceDescriptions.Item>
                                <ClusterLoadChart cluster={cluster} />
                            </SpaceDescriptions.Item>
                        </SpaceDescriptions>
                    ),
                progress: true,
                onCell: (cluster: CcCluster) => ({
                    // colSpan: cluster.deploymentJobId ? 3 : 1,
                    width: '100%',
                }),
            },
            {
                key: 'actions',
                align: 'center',
                render: (cluster: CcCluster) => (
                    <UserAclManageCluster cluster={cluster}>
                        <ClusterActionsMenu
                            cluster={cluster}
                            style={{ display: 'flex' }}
                            bordered={true}
                            onActionPerformed={handleActionPerformed}
                            disabled={!cluster.clusterId}
                        />
                    </UserAclManageCluster>
                ),
                actions: true,
                onCell: () => ({ style: { verticalAlign: 'middle' } }),
                width: responsive ? 'auto' : '100%',
            },
        ],
        [filterParams, addFilterParams]
    );

    const clusterHasReplication = (cluster: CcCluster) =>
        cluster.replicationPrimaries.length > 0 ||
        cluster.replicationSecondaries.length > 0;

    const clusterHasExpandableRow = (cluster: CcCluster) =>
        clusterHasReplication(cluster);

    const expandedRowRender = useCallback(
        (record: CcCluster) => {
            const secondaries = arrayDifference(
                record.replicationSecondaries,
                record.replicationPrimaries
            );
            return (
                <div>
                    <AppDivider />{' '}
                    <SpaceDescriptions>
                        {record.replicationPrimaries.length > 0 ? (
                            <SpaceDescriptions.Item label="Replica of">
                                {record.replicationPrimaries.map(
                                    (relatedCluster) => (
                                        <ClusterReplicationFormat
                                            cluster={relatedCluster}
                                            secondaryCluster={record}
                                            isBidirectional={record.isReplicationBidirectional(
                                                relatedCluster
                                            )}
                                        />
                                    )
                                )}
                            </SpaceDescriptions.Item>
                        ) : null}
                        {secondaries.length > 0 ? (
                            <SpaceDescriptions.Item label="Replicated clusters">
                                {secondaries.map((relatedCluster) => (
                                    <ClusterReplicationFormat
                                        cluster={relatedCluster}
                                        isBidirectional={record.isReplicationBidirectional(
                                            relatedCluster
                                        )}
                                    />
                                ))}
                            </SpaceDescriptions.Item>
                        ) : null}
                    </SpaceDescriptions>
                </div>
            );
        },
        [clusters]
    );

    const handleFilterChanged = (values: ClusterFilterParams) => {
        addFilterParams(
            merge(
                {
                    sort: undefined,
                    clusterTypes: undefined,
                    nodeTypes: undefined,
                    state: undefined,
                    tags: undefined,
                },
                values
            )
        );
    };

    const pagination = {
        pageSize,
        current: filterParams?.page || 1,
        total,
        hideOnSinglePage: true,
        showSizeChanger: false,
    };
    return (
        <div className="ClusterList">
            <AppPageWrapper
                title={
                    <Space>
                        <ClustersIcon /> Clusters
                    </Space>
                }
                headerMenu={
                    filterParams && (
                        <ClusterFilter
                            filterParams={filterParams}
                            onChange={handleFilterChanged}
                        />
                    )
                }
                className="ClusterList_wrapper"
                transparentBackground={true}
                noPadding={true}
                horizontalScroll={true}
            >
                <LicenseSpaceWrapper>
                    <TableWithJobRow
                        command={CmonJobInstanceCommand.CREATE_CLUSTER}
                        jobRecord={(job) =>
                            new CcCluster({
                                deployment_job_id: job.jobId,
                                cluster_name: job.spec.job_data.cluster_name,
                                cluster_type: job.spec.job_data.cluster_type?.toUpperCase(),
                                vendor: job.spec.job_data.vendor,
                                version: job.spec.job_data.version,
                            })
                        }
                        className="ClusterList_table"
                        asListView={true}
                        loading={loadingClusters}
                        rowKey={(record: CcCluster) => record.getKey()}
                        dataSource={clusters || []}
                        columns={columns}
                        pagination={pagination}
                        size="middle"
                        onChange={handleTableChange}
                        responsive={responsive}
                        rowExpandable={(record: CcCluster) =>
                            clusterHasExpandableRow(record)
                        }
                        expandedRowClassName={() => 'ClusterList_expanded-row'}
                        expandedRowKeys={clusters?.map((c) => c.getKey())}
                        showExpandColumn={false}
                        expandedRowRender={expandedRowRender}
                        onRow={(record: CcCluster, index: number) => ({
                            'data-testid': `cluster-list-row-${index}`,
                            className: classNames('ClusterList_row', {
                                'ClusterList_row--expandable': clusterHasExpandableRow(
                                    record
                                ),
                            }),
                        })}
                        renderEmpty={
                            totalFiltered ? (
                                <FilteredTableEmpty onReset={resetFilters} />
                            ) : (
                                <ClustersEmpty loading={loadingClusters} />
                            )
                        }
                        footer={() => (
                            <ResponsiveTableFooter
                                pagination={pagination}
                                currentPageLength={clusters && clusters.length}
                            />
                        )}
                    />
                </LicenseSpaceWrapper>
            </AppPageWrapper>
        </div>
    );
}
