import React from 'react';
import SpaceDescriptions from '../../../common/Layout/SpaceDescriptions';
import AppDivider from '../../../common/AppDivider';
import { getBackupStorageLocationName } from './BackupStorageForm';
import CronFormat from '@severalnines/bar-frontend-components/build/lib/Format/CronFormat';
import { CcBackupRetentionType } from '../../../services/models/CcBackup';
import { getBackupAdvancedFormFieldName } from './BackupAdvancedForm';
import { CLUSTER_ALL_DATABASES_NAME } from '../../Clusters/ClusterHostDatabasesSelect';
import { CLUSTER_ALL_TABLES_NAME } from '../../Clusters/ClusterHostDatabaseTablesSelect';
import AppRow from '../../../common/AppRow';
import { Col, Space } from 'antd';
import { MULTI_SELECT_ALL_OPTION_VALUE } from '../../../common/DataEntry/MultiSelect';
import CloudProviderTypeFormat from '../../Integrations/CloudProviderTypeFormat';
import { pluralize } from '../../../common/helpers';
import YesNoFormat from '../../../common/Format/YesNoFormat';
import { NodesIcon } from '../../../common/icons/icons';
import TypographyText from '../../../common/TypographyText';

import BackupFormConfigurator, {
    BackupFormFieldsType,
    BackupFormWizardStep,
} from '../Config/BackupFormConfigurator';
import { getBackupConfigurationMysqlDumpTypeName } from '../FormParts/BackupConfigurationFields';

export type BackupWizardFormSummaryProps = {
    configurator: BackupFormConfigurator;
};
const ADVANCED_FIELDS_LIST: (keyof BackupFormFieldsType)[] = [
    'enablePartial',
    'extendedInsert',
    'encryptBackup',
    'splitDumpFiles',
    'useQPress',
    'wsrepDeSync',
    'xtrabackupBackupLocks',
    'xtrabackupLockDdlPerTable',
    'xtrabackupParallellism',
    'failoverBackup',
    'throttleRateNet',
    'usePIGZ',
    'dataStreamsAndIndexes',
    'allowPartialIndexes',
    'ignoreUnavailableIndexes',
    'includeGlobalState',
];
const BackupWizardFormSummary = ({
    configurator,
}: BackupWizardFormSummaryProps) => {
    const fields = configurator.getAvailableFields();

    return (
        <div className="BackupWizardFormSummary">
            {fields.isSchedule ? (
                <>
                    <SpaceDescriptions
                        direction="vertical"
                        title="Backup schedule"
                        size="small"
                        alignItems="right"
                    >
                        <SpaceDescriptions.Item>
                            <CronFormat>{fields?.scheduleCron}</CronFormat>
                        </SpaceDescriptions.Item>
                    </SpaceDescriptions>
                    <AppDivider />
                </>
            ) : null}
            <SpaceDescriptions
                direction="vertical"
                title="Configuration"
                size="small"
                alignItems="right"
            >
                <SpaceDescriptions.Item label="Backup method" labelStrong>
                    {fields.method}
                </SpaceDescriptions.Item>
                {fields.host ? (
                    <SpaceDescriptions.Item label="Host" labelStrong>
                        {fields.host === 'auto' ? 'Auto Select' : fields.host}
                    </SpaceDescriptions.Item>
                ) : null}
                {fields.port ? (
                    <SpaceDescriptions.Item label="Port" labelStrong>
                        {fields.port}
                    </SpaceDescriptions.Item>
                ) : null}
                {fields.dumpType ? (
                    <SpaceDescriptions.Item label="Dump type" labelStrong>
                        {getBackupConfigurationMysqlDumpTypeName(
                            fields.dumpType
                        )}
                    </SpaceDescriptions.Item>
                ) : null}

                {fields.elasticRepository === undefined ? null : (
                    <SpaceDescriptions.Item label="Repository" labelStrong>
                        <Space>
                            <NodesIcon />
                            <span>default</span>
                        </Space>
                    </SpaceDescriptions.Item>
                )}
            </SpaceDescriptions>
            <AppDivider />
            {configurator.stepAvailable(BackupFormWizardStep.ADVANCED) ? (
                <>
                    <SpaceDescriptions
                        direction="vertical"
                        title="Advanced settings"
                        size="small"
                        alignItems="right"
                    >
                        <AppRow wrap gutter={[24, 0]}>
                            {fields.compression !== undefined ? (
                                <Col span={12}>
                                    <SpaceDescriptions.Item
                                        label="Compression"
                                        labelStrong
                                    >
                                        <YesNoFormat
                                            booleanVar={fields.compression}
                                        />
                                    </SpaceDescriptions.Item>
                                </Col>
                            ) : null}
                            {fields.compressionLevel !== undefined ? (
                                <Col span={12}>
                                    <SpaceDescriptions.Item
                                        label="Compression level"
                                        labelStrong
                                    >
                                        {fields.compressionLevel}
                                    </SpaceDescriptions.Item>
                                </Col>
                            ) : null}
                            {configurator.available('enableRetention') ? (
                                <Col span={12}>
                                    <SpaceDescriptions.Item
                                        label="Retention"
                                        labelStrong
                                    >
                                        {getBackupRetentionText(fields)}
                                    </SpaceDescriptions.Item>
                                </Col>
                            ) : null}
                            {fields.enablePartial &&
                            fields.partialDatabases?.length ? (
                                <Col span={12}>
                                    <SpaceDescriptions.Item
                                        label="Include databases"
                                        labelStrong
                                    >
                                        <TypographyText
                                            style={{ maxWidth: 200 }}
                                            ellipsis={{
                                                tooltip: fields.partialDatabases
                                                    ?.map((db: string) =>
                                                        db ===
                                                        MULTI_SELECT_ALL_OPTION_VALUE
                                                            ? CLUSTER_ALL_DATABASES_NAME
                                                            : db
                                                    )
                                                    .join(','),
                                            }}
                                        >
                                            {fields.partialDatabases
                                                ?.map((db: string) =>
                                                    db ===
                                                    MULTI_SELECT_ALL_OPTION_VALUE
                                                        ? CLUSTER_ALL_DATABASES_NAME
                                                        : db
                                                )
                                                .join(',')}
                                        </TypographyText>
                                    </SpaceDescriptions.Item>
                                </Col>
                            ) : null}
                            {fields.enablePartial &&
                            (fields.includeTables || fields.excludeTables) ? (
                                <Col span={12}>
                                    <SpaceDescriptions.Item
                                        label={`${
                                            fields.includeTables
                                                ? 'Include'
                                                : 'Exclude'
                                        } tables`}
                                        labelStrong
                                    >
                                        <TypographyText
                                            style={{ maxWidth: 200 }}
                                            ellipsis={{
                                                tooltip: fields.partialTables
                                                    ?.map((tbl: string) =>
                                                        tbl ===
                                                        MULTI_SELECT_ALL_OPTION_VALUE
                                                            ? CLUSTER_ALL_TABLES_NAME
                                                            : tbl
                                                    )
                                                    .join(','),
                                            }}
                                        >
                                            {fields.partialTables
                                                ?.map((tbl: string) =>
                                                    tbl ===
                                                    MULTI_SELECT_ALL_OPTION_VALUE
                                                        ? CLUSTER_ALL_TABLES_NAME
                                                        : tbl
                                                )
                                                .join(',')}
                                        </TypographyText>
                                    </SpaceDescriptions.Item>
                                </Col>
                            ) : null}
                            {ADVANCED_FIELDS_LIST.filter(
                                (field) => fields[field] !== undefined
                            ).map((field) => (
                                <Col span={12} key={field}>
                                    <SpaceDescriptions.Item
                                        label={getBackupAdvancedFormFieldName(
                                            field
                                        )}
                                        labelStrong
                                    >
                                        {fields[field] === true ||
                                        fields[field] === false ? (
                                            <YesNoFormat
                                                booleanVar={
                                                    fields[field] as boolean
                                                }
                                            />
                                        ) : (
                                            fields[field]
                                        )}
                                    </SpaceDescriptions.Item>
                                </Col>
                            ))}
                        </AppRow>
                    </SpaceDescriptions>
                    <AppDivider />
                </>
            ) : null}

            {fields.verifyBackup ? (
                <>
                    <SpaceDescriptions
                        direction="vertical"
                        title="Verify backup"
                        size="small"
                        alignItems="right"
                    >
                        <SpaceDescriptions.Item
                            label="Verify on host"
                            labelStrong
                        >
                            {fields.verificationHost?.[0] &&
                                fields.verificationHost?.[0].extraData
                                    ?.hostname}
                        </SpaceDescriptions.Item>
                        <SpaceDescriptions.Item
                            label="Install software"
                            labelStrong
                        >
                            <YesNoFormat
                                booleanVar={fields.verificationInstallSoftware}
                            />
                        </SpaceDescriptions.Item>
                        <SpaceDescriptions.Item
                            label="Disable firewall"
                            labelStrong
                        >
                            <YesNoFormat
                                booleanVar={fields.verificationDisableFirewall}
                            />
                        </SpaceDescriptions.Item>
                        <SpaceDescriptions.Item
                            label="Disable AppArmor/SELinux"
                            labelStrong
                        >
                            <YesNoFormat
                                booleanVar={fields.verificationDisableSELinux}
                            />
                        </SpaceDescriptions.Item>
                        <SpaceDescriptions.Item
                            label="Shut down the server after backup"
                            labelStrong
                        >
                            <YesNoFormat
                                booleanVar={fields.verificationShutdownAfter}
                            />
                        </SpaceDescriptions.Item>
                        {fields.verificationStartIn ? (
                            <SpaceDescriptions.Item
                                label="Start restoring"
                                labelStrong
                            >
                                {`In ${fields.verificationStartIn} hours`}
                            </SpaceDescriptions.Item>
                        ) : null}
                    </SpaceDescriptions>
                    <AppDivider />
                </>
            ) : null}
            {fields.cloudUpload ? (
                <>
                    <SpaceDescriptions
                        direction="vertical"
                        title="Cloud storage"
                        size="small"
                        alignItems="right"
                    >
                        <SpaceDescriptions.Item
                            label="Credentials ID"
                            labelStrong
                        >
                            {fields.cloudCredentials}
                        </SpaceDescriptions.Item>
                        {fields.cloudProvider && (
                            <SpaceDescriptions.Item
                                label="Cloud provider"
                                labelStrong
                            >
                                <CloudProviderTypeFormat
                                    provider={fields.cloudProvider}
                                />
                            </SpaceDescriptions.Item>
                        )}
                        <SpaceDescriptions.Item label="Bucket" labelStrong>
                            {fields.cloudBucket}
                        </SpaceDescriptions.Item>
                        <SpaceDescriptions.Item
                            label="Location path"
                            labelStrong
                        >
                            {fields.cloudLocationPath || 'None'}
                        </SpaceDescriptions.Item>
                        <SpaceDescriptions.Item
                            label="Retention period"
                            labelStrong
                        >
                            {fields.cloudEnableRetention
                                ? pluralize(
                                      parseInt(
                                          `${
                                              fields.cloudCustomRetention ||
                                              fields.cloudDefaultRetention ||
                                              0
                                          }`
                                      ),
                                      'day'
                                  )
                                : 'Keep forever'}
                        </SpaceDescriptions.Item>
                        <SpaceDescriptions.Item
                            label="Delete after upload"
                            labelStrong
                        >
                            <YesNoFormat
                                booleanVar={fields.deleteAfterUpload}
                            />
                        </SpaceDescriptions.Item>
                    </SpaceDescriptions>
                    <AppDivider />
                </>
            ) : null}

            {configurator.stepAvailable(BackupFormWizardStep.STORAGE) ? (
                <SpaceDescriptions
                    direction="vertical"
                    title={fields.cloudUpload ? 'Local storage' : 'Storage'}
                    size="small"
                    alignItems="right"
                >
                    {fields.storageLocation !== undefined && (
                        <>
                            <SpaceDescriptions.Item
                                label="Storage location"
                                labelStrong
                            >
                                {getBackupStorageLocationName(
                                    fields.storageLocation ? 1 : 0
                                )}
                            </SpaceDescriptions.Item>
                        </>
                    )}
                    {fields.storageHost && (
                        <>
                            <SpaceDescriptions.Item
                                label="Storage host"
                                labelStrong
                            >
                                {fields.storageHost}
                            </SpaceDescriptions.Item>
                        </>
                    )}
                    <SpaceDescriptions.Item
                        label="Storage directory"
                        labelStrong
                    >
                        {fields.storageDirectory}
                    </SpaceDescriptions.Item>
                    <SpaceDescriptions.Item
                        label="Backup subdirectory"
                        labelStrong
                    >
                        {fields.storageSubdirectory}
                    </SpaceDescriptions.Item>
                </SpaceDescriptions>
            ) : null}
        </div>
    );
};

function getBackupRetentionText({
    enableRetention,
    customRetention,
}: BackupFormFieldsType) {
    const retention = enableRetention
        ? customRetention || CcBackupRetentionType.DEFAULT
        : CcBackupRetentionType.FOREVER;
    switch (retention) {
        case CcBackupRetentionType.DEFAULT:
            return '31 days (default)';
        case CcBackupRetentionType.FOREVER:
            return 'Keep forever';
        default:
            return retention > 1 ? `${retention} Days` : `${retention} Day`;
    }
}

export default BackupWizardFormSummary;
