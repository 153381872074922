import React, { useMemo } from 'react';
import { Alert, Col, Form, Input, Row, Space } from 'antd';
import CcCluster, {
    CcClusterTechnology,
    CcClusterType,
} from '../../../../services/models/CcCluster';
import { FormInstance } from 'antd/es';
import NodeConfigurationTemplateField from '../../../../common/Form/Fields/NodeConfigurationTemplateField';
import NetcatPortField from '../../../../common/Form/Fields/NetcatPortField';
import NodePortField from '../../../../common/Form/Fields/NodePortField';
import DataDirectoryField from '../../../../common/Form/Fields/DataDirectoryField';
import GaleraSegmentField from '../../../../common/Form/Fields/GaleraSegmentField';
import IncludeInLoadBalancerSwitch from '../../../../common/Form/Fields/IncludeInLoadBalancerSwitch';
import InstallSoftwareSwitch from '../../../../common/Form/Fields/InstallSoftwareSwitch';
import DisableFirewallField from '../../../../common/Form/Fields/DisableFirewallField';
import DisableSelinuxField from '../../../../common/Form/Fields/DisableSelinuxField';
import FormItemInlineSwitch from '../../../../common/DataEntry/FormItemInlineSwitch';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import MongoNodeTypeSelectField, {
    MongoNodeType,
} from '../../../../common/Form/Fields/MongoNodeTypeSelectField';
import MongoReplicaSetSelectField from '../../../../common/Form/Fields/MongoReplicaSetSelectField';

export default AddNodeConfigurationForm;

export type AddNodeConfigurationFormFields = {
    nodePort?: number;
    mssqlServerPort?: number;
    redisSentinelPort?: number;
    netcatPorts?: string;
    installSoftware?: boolean;
    disableFirewall?: boolean;
    disableSeLinux?: boolean;
    includeLB?: boolean;
    dataDir?: string;
    galeraSegment?: number;
    configurationTemplate?: string;
    usePackageForDataDir?: boolean;
    customLogFile?: string;
    mongoNodeType?: MongoNodeType;
    isMongoRouter?: boolean;
    mongoReplicaSet?: string;
};
type AddNodeConfigurationFormFieldList = (keyof AddNodeConfigurationFormFields)[];
export type AddNodeConfigurationFormProps = {
    cluster: CcCluster;
    form: FormInstance;
    availableFields?: AddNodeConfigurationFormFieldList;
};

function AddNodeConfigurationForm({
    cluster,
    form,
    availableFields = ['nodePort'],
}: AddNodeConfigurationFormProps) {
    const installSoftwareOnInfo = useMemo(
        () =>
            (cluster.isType(CcClusterType.TYPE_MONGODB) ||
                cluster.isType(CcClusterType.TYPE_MONGODB_SHARDS)) && (
                <Alert
                    style={{
                        marginBottom: 15,
                    }}
                    message={
                        <span>
                            Use clean and minimal VMs. Existing MongoDb
                            dependencies will be removed if 'yes' is selected.
                        </span>
                    }
                />
            ),
        [cluster]
    );
    return (
        <div className="AddNodeConfigurationForm">
            <Form.Item shouldUpdate>
                {() => {
                    return (
                        <>
                            <Row gutter={[24, 0]}>
                                <Col span={24}>
                                    <h3>Node configuration</h3>
                                </Col>
                            </Row>
                            <Row gutter={[24, 0]}>
                                <Col span={24}>
                                    {cluster.isMongoShard() &&
                                    availableFields.includes(
                                        'isMongoRouter'
                                    ) ? (
                                        <Col xs={24} sm={24} md={12}>
                                            <FormItemInlineSwitch
                                                justify={true}
                                                name="isMongoRouter"
                                                label="Create router/mongos"
                                                valuePropName="checked"
                                            />
                                        </Col>
                                    ) : null}
                                </Col>
                            </Row>
                            <Row gutter={[24, 0]}>
                                {!form.getFieldValue('isMongoRouter') &&
                                availableFields.includes('mongoNodeType') ? (
                                    <Col xs={24} sm={24} md={12}>
                                        <MongoNodeTypeSelectField
                                            isShard={cluster.isMongoShard()}
                                        />
                                    </Col>
                                ) : null}

                                {!form.getFieldValue('isMongoRouter') &&
                                availableFields.includes('mongoReplicaSet') ? (
                                    <Col xs={24} sm={24} md={12}>
                                        <MongoReplicaSetSelectField
                                            cluster={cluster}
                                        />
                                    </Col>
                                ) : null}
                            </Row>
                            <Row gutter={[24, 0]}>
                                {availableFields.includes('netcatPorts') ? (
                                    <Col xs={24} sm={24} md={12}>
                                        <NetcatPortField disabled />
                                    </Col>
                                ) : null}
                                {availableFields.includes('nodePort') ? (
                                    <Col xs={24} sm={24} md={12}>
                                        <NodePortField />
                                    </Col>
                                ) : null}
                                {availableFields.includes('mssqlServerPort') ? (
                                    <Col xs={24} sm={24} md={12}>
                                        <NodePortField
                                            name="mssqlServerPort"
                                            inputProps={{
                                                disabled: true,
                                                readOnly: true,
                                            }}
                                        />
                                    </Col>
                                ) : null}
                                {availableFields.includes(
                                    'redisSentinelPort'
                                ) ? (
                                    <Col xs={24} sm={24} md={12}>
                                        <NodePortField
                                            label="Redis sentinel port"
                                            name="redisSentinelPort"
                                        />
                                    </Col>
                                ) : null}
                                {availableFields.includes('dataDir') ? (
                                    <Col xs={24} sm={24} md={12}>
                                        <DataDirectoryField />
                                    </Col>
                                ) : null}
                                {availableFields.includes('galeraSegment') ? (
                                    <Col xs={24} sm={24} md={12}>
                                        <GaleraSegmentField />
                                    </Col>
                                ) : null}
                                {availableFields.includes(
                                    'configurationTemplate'
                                ) &&
                                cluster.vendor &&
                                cluster.version ? (
                                    <Col xs={24} sm={24} md={12}>
                                        <NodeConfigurationTemplateField
                                            vendor={cluster.vendor}
                                            version={cluster.version}
                                            clusterType={cluster.clusterType}
                                        />
                                    </Col>
                                ) : null}
                                {availableFields.includes('customLogFile') ? (
                                    <Col xs={24} sm={24} md={12}>
                                        <Form.Item
                                            name="customLogFile"
                                            label={
                                                <Space>
                                                    <span>Logfile path</span>
                                                    <InfoIcon
                                                        info={
                                                            <span>
                                                                Logfile path
                                                                (Optional,
                                                                Specify only if
                                                                logging_collector
                                                                = OFF in
                                                                postgresql.conf)
                                                            </span>
                                                        }
                                                    />
                                                </Space>
                                            }
                                        >
                                            <Input placeholder="Enter logfile path" />
                                        </Form.Item>
                                    </Col>
                                ) : null}
                                {availableFields.includes('includeLB') ? (
                                    <Col xs={24} sm={24} md={12}>
                                        <IncludeInLoadBalancerSwitch />
                                    </Col>
                                ) : null}
                            </Row>
                            <Row gutter={[24, 0]}>
                                {availableFields.includes(
                                    'usePackageForDataDir'
                                ) ? (
                                    <Col xs={24} sm={24} md={12}>
                                        <FormItemInlineSwitch
                                            justify={true}
                                            name="usePackageForDataDir"
                                            label="Use package default for datadir"
                                            valuePropName="checked"
                                            extraOffSwitch={
                                                <DataDirectoryField />
                                            }
                                        />
                                    </Col>
                                ) : null}
                                {availableFields.includes('installSoftware') ? (
                                    <Col xs={24} sm={24} md={12}>
                                        <InstallSoftwareSwitch
                                            extraOnSwitch={
                                                installSoftwareOnInfo
                                            }
                                        />
                                    </Col>
                                ) : null}
                            </Row>
                            {availableFields.includes('disableFirewall') &&
                                availableFields.includes('disableSeLinux') && (
                                    <Row gutter={[24, 0]}>
                                        <Col span={24}>
                                            <h3>Security configuration</h3>
                                        </Col>
                                        {availableFields.includes(
                                            'disableFirewall'
                                        ) ? (
                                            <Col xs={24} sm={24} md={12}>
                                                <DisableFirewallField />
                                            </Col>
                                        ) : null}
                                        {availableFields.includes(
                                            'disableSeLinux'
                                        ) ? (
                                            <Col xs={24} sm={24} md={12}>
                                                <DisableSelinuxField />
                                            </Col>
                                        ) : null}
                                    </Row>
                                )}
                            {cluster?.isTechnology(
                                CcClusterTechnology.TECHNOLOGY_MYSQL
                            ) &&
                                availableFields.includes('installSoftware') &&
                                !form?.getFieldValue('installSoftware') && (
                                    <Row>
                                        <Alert
                                            message={
                                                <ul
                                                    style={{
                                                        margin: 0,
                                                    }}
                                                >
                                                    <li>
                                                        The MySQL server must
                                                        not be running when
                                                        launching the command.
                                                    </li>
                                                    <li>
                                                        It must be reachable by
                                                        SSH (key-based auth.)
                                                        from the controller.
                                                    </li>
                                                    <li>
                                                        Percona Xtrabackup must
                                                        be installed in
                                                        /usr/bin/ or on the PATH
                                                    </li>
                                                    <li>
                                                        The my.cnf must be
                                                        stored as /etc/my.cnf
                                                        (for redhat/centos) or
                                                        /etc/mysql/my.cnf
                                                        (ubuntu/debian).
                                                    </li>
                                                </ul>
                                            }
                                            showIcon
                                            type="warning"
                                        />
                                    </Row>
                                )}
                            {availableFields.includes('netcatPorts') && (
                                <Row>
                                    <Alert
                                        message={
                                            <span>
                                                Data will be streamed using a
                                                port in the range: 9999,
                                                9990-9998. Go to backup settings
                                                to change this range
                                            </span>
                                        }
                                        showIcon
                                        type="info"
                                    />
                                </Row>
                            )}
                        </>
                    );
                }}
            </Form.Item>
        </div>
    );
}

export function getNewReplicationNodeConfigurationFields(
    clusterType: CcClusterType
): AddNodeConfigurationFormFieldList {
    switch (clusterType) {
        case CcClusterType.TYPE_REPLICATION:
        case CcClusterType.TYPE_GALERA:
            return [
                'dataDir',
                'netcatPorts',
                'nodePort',
                'installSoftware',
                'disableFirewall',
                'disableSeLinux',
                'configurationTemplate',
            ];
        case CcClusterType.TYPE_TIMESCALEDB:
        case CcClusterType.TYPE_POSTGRESQL:
            return [
                'dataDir',
                'nodePort',
                'installSoftware',
                'usePackageForDataDir',
            ];
        default:
            return [];
    }
}

export function getImportReplicationNodeConfigurationFields(
    clusterType: CcClusterType
): AddNodeConfigurationFormFieldList {
    switch (clusterType) {
        case CcClusterType.TYPE_REPLICATION:
        case CcClusterType.TYPE_GALERA:
            return ['nodePort', 'includeLB'];
        case CcClusterType.TYPE_TIMESCALEDB:
        case CcClusterType.TYPE_POSTGRESQL:
            return ['nodePort', 'includeLB', 'customLogFile'];
        default:
            return [];
    }
}

export function getNewDatabaseNodeConfigurationFields(
    clusterType: CcClusterType
): AddNodeConfigurationFormFieldList {
    switch (clusterType) {
        case CcClusterType.TYPE_GALERA:
            return [
                'dataDir',
                'galeraSegment',
                'configurationTemplate',
                'installSoftware',
                'disableFirewall',
                'disableSeLinux',
            ];
        case CcClusterType.TYPE_MONGODB:
        case CcClusterType.TYPE_MONGODB_SHARDS:
            return [
                'nodePort',
                'installSoftware',
                'disableFirewall',
                'disableSeLinux',
                'mongoNodeType',
                'isMongoRouter',
                'mongoReplicaSet',
            ];
        case CcClusterType.TYPE_ELASTIC:
            return [
                'nodePort',
                'installSoftware',
                'disableFirewall',
                'disableSeLinux',
            ];
        case CcClusterType.TYPE_REDIS:
            return [
                'nodePort',
                'redisSentinelPort',
                'installSoftware',
                'disableFirewall',
                'disableSeLinux',
            ];
        case CcClusterType.TYPE_MSSQL_AO_ASYNC:
            return [
                'mssqlServerPort',
                'installSoftware',
                'disableFirewall',
                'disableSeLinux',
            ];
        default:
            return [];
    }
}

export function getImportDatabaseNodeConfigurationFields(
    clusterType: CcClusterType
): AddNodeConfigurationFormFieldList {
    switch (clusterType) {
        case CcClusterType.TYPE_GALERA:
            return ['nodePort', 'dataDir', 'includeLB'];
        case CcClusterType.TYPE_MONGODB:
        case CcClusterType.TYPE_MONGODB_SHARDS:
            return ['nodePort'];
        default:
            return [];
    }
}

export function getNodeConfigurationValidatingFields(): AddNodeConfigurationFormFieldList {
    return [
        'nodePort',
        'dataDir',
        'configurationTemplate',
        'mongoReplicaSet',
        'mongoNodeType',
        'redisSentinelPort',
        'mssqlServerPort',
    ];
}
