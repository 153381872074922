import CcCluster from '../../services/models/CcCluster';
import CcImperativeService from '../../services/CcImperativeService';
import CmonReportsService from '../../services/cmon/CmonReportsService';
import CcJob from '../../services/models/CcJob';
import CcLicense from '../../services/models/CcLicense';
import CcLicenseCheck from '../../services/models/CcLicenseCheck';
import CmonBackupService from '../../services/cmon/CmonBackupService';
import CmonCloudService from '../../services/cmon/CmonCloudService';
import CmonClustersService from '../../services/cmon/CmonClustersService';
import CmonDiscoveryService from '../../services/cmon/CmonDiscoveryService';
import CmonJobsService from '../../services/cmon/CmonJobsService';
import CmonRequestService from '../../services/cmon/CmonRequestService';
import CmonUsersService from '../../services/cmon/CmonUsersService';
import CmonTreeService from '../../services/cmon/CmonTreeService';
import CmonConfigService from '../../services/cmon/CmonConfigService';
import CmonMaintenanceService from '../../services/cmon/CmonMaintenanceService';

import FormFooter from '../../common/FormFooter';
import getAllClusterInfo from '../dataMocks/rpc/clusters/getAllClusterInfo1';
import getConfig1 from '../dataMocks/rpc/clusters/getConfig1';
import getJobInstances from '../dataMocks/rpc/jobs/getJobInstances';
import listCredentials1 from '../dataMocks/rpc/cloud/listCredentials1';
import proxyBucketList1 from '../dataMocks/rpc/cloud/proxyBucketList1';
import getTreeResult from '../dataMocks/rpc/tree/getTree';
import getUsersResult from '../dataMocks/rpc/users/getUsers';
import getGroupsResult from '../dataMocks/rpc/users/getGroups';
import React, { useCallback, useEffect, useRef } from 'react';
import show_dbs from '../dataMocks/rpc/imperative/show_dbs';
import show_tables from '../dataMocks/rpc/imperative/show_tables';
import { Button } from 'antd';
import { notifyModal } from '../../components/Notifications/uiNotification';
import getConfigGrouped1 from '../dataMocks/rpc/clusters/getConfigGrouped1';
import getJobInstances_schedules from '../dataMocks/rpc/jobs/getJobInstances_schedules';
import listErrorReports from '../dataMocks/rpc/reports/listErrorReports';
import getLdapConfigResult from '../dataMocks/rpc/config/getLdapConfig';
import whoAmI from '../dataMocks/rpc/users/whoAmI';
import getMaintenance from '../dataMocks/rpc/maintenance/getMaintenance';
import CmonDbversionsService, {
    GetDbVersionsRequestData,
} from '../../services/cmon/CmonDbversionsService';
import GetDbVersions_mariadb_patch from '../dataMocks/rpc/dbversions/getDbVersions_mariadb_patch';
import GetDbVersions_mariadb from '../dataMocks/rpc/dbversions/getDbVersions_mariadb';
import GetDbVersions_mysql_percona from '../dataMocks/rpc/dbversions/getDbVersions_mysql_percona';
import GetDbVersions_mysql_percona_patch from '../dataMocks/rpc/dbversions/getDbVersions_mysql_percona_patch';

export default ServiceMockProvider;
type ActualType = { [key: string]: any };

interface ServiceMockContextInterface {
    mockMethod: (method: ServiceMethod, mock?: any) => void;
    getActual: (id: string) => ActualType | undefined;
    saveActual: (id: string, methods: ServiceMethod[]) => void;
    restoreActual: (id: string) => void;
}

const classes: { [key: string]: any } = {
    CmonRequestService,
    CmonJobsService,
    CmonDiscoveryService,
    CmonClustersService,
    CmonBackupService,
    CmonCloudService,
    CcImperativeService,
    CmonReportsService,
    CmonUsersService,
    CmonTreeService,
    CmonConfigService,
    CmonMaintenanceService,
    CmonDbversionsService,
};

export enum ServiceMethod {
    DO_REQUEST = 'CmonRequestService.doRequest',
    CREATE_JOB_INSTANCE = 'CmonJobsService.createJobInstance',
    CHECK_HOSTS = 'CmonDiscoveryService.checkHosts',
    GET_SNAPSHOT_REPOSITORIES = 'CmonBackupService.getSnapshotRepositories',
    GET_ALL_CLUSTER_INFO = 'CmonClustersService.getAllClusterInfo',
    GET_CONFIG = 'CmonClustersService.getConfig',
    GET_JOB_INSTANCES = 'CmonJobsService.getJobInstances',
    GET_JOB_INSTANCE = 'CmonJobsService.getJobInstance',
    CLOUD_LIST_CREDENTIALS = 'CmonCloudService.listCredentials',
    CLOUD_PROXY = 'CmonCloudService.proxy',
    EXECUTE_SCRIPT = 'CcImperativeService.executeScript',
    USER_GET_GROUPS = 'CmonUsersService.getGroups',
    USER_GET_USERS = 'CmonUsersService.getUsers',
    USER_CREATE = 'CmonUsersService.createUser',
    GROUP_CREATE = 'CmonUsersService.createGroup',
    ADD_TO_GROUP = 'CmonUsersService.addToGroup',
    GET_TREE = 'CmonTreeService.getTree',
    ADD_ACL = 'CmonTreeService.addAcl',
    GET_CONFIG_GROUPED = 'CmonClustersService.getConfigGrouped',
    CREATE_ENABLE_LOG_ARCHIVING = 'CmonJobsService.createEnableLogArchivingJobInstance',
    SET_CONFIG = 'CmonClustersService.setConfig',
    LIST_ERROR_REPORTS = 'CmonReportsService.listErrorReports',
    REMOVE_ERROR_REPORT = 'CmonReportsService.removeErrorReport',
    DOWNLOAD_ERROR_REPORTS = 'CmonReportsService.downloadErrorReport',
    GET_LDAP_CONFIG = 'CmonConfigService.getLdapConfig',
    SET_LDAP_CONFIG = 'CmonConfigService.setLdapConfig',
    WHO_AM_I = 'CmonUsersService.whoAmI',
    GET_MAINTENANCE = 'CmonMaintenanceService.getMaintenance',
    GET_DB_VERSIONS = 'CmonDbversionsService.getDbVersions',
}

export const ServiceMockContext = React.createContext<
    ServiceMockContextInterface
>({
    mockMethod: () => {},
    getActual: () => undefined,
    saveActual: () => {},
    restoreActual: () => {},
});

export type ServiceMockProviderProps = { children: React.ReactNode };

function ServiceMockProvider({ children }: ServiceMockProviderProps) {
    const actual = useRef<{ [key: string]: ActualType }>({});

    const mockMethod = useCallback((method: ServiceMethod, mock?: any) => {
        setMethod(method, mock || getServiceMock(method));
    }, []);

    const getActual = useCallback((id: string) => {
        return actual.current[id];
    }, []);
    const saveActual = useCallback((id: string, methods: ServiceMethod[]) => {
        actual.current[id] = methods.reduce((a, c) => {
            if (!a[c]) {
                a[c] = getMethod(c);
            }
            return a;
        }, {} as any);
    }, []);

    const restoreActual = useCallback((id: string) => {
        if (actual.current[id]) {
            Object.entries(actual.current[id]).forEach(
                ([key, actualMethod]) => {
                    setMethod(key as ServiceMethod, actualMethod);
                }
            );
            delete actual.current[id];
        }
    }, []);

    useEffect(() => {
        // reset actual on hot reload
        if (Object.keys(actual.current).length > 0) {
            actual.current = {};
        }
    }, [classes]);

    const mockContext = {
        mockMethod,
        getActual,
        saveActual,
        restoreActual,
    };
    return (
        <ServiceMockContext.Provider value={mockContext}>
            {children}
        </ServiceMockContext.Provider>
    );
}

function getServiceMock(method: ServiceMethod) {
    switch (method) {
        case ServiceMethod.DO_REQUEST:
            return async (operation: string, data?: any, opts?: any) => {
                console.log(`request: ${ServiceMethod.DO_REQUEST}`, {
                    operation,
                    data,
                    opts,
                });
                return Promise.resolve({});
            };
        case ServiceMethod.CREATE_ENABLE_LOG_ARCHIVING:
            return async (clusterId: number, spec: any, opts: any) => {
                return debugModal(
                    {
                        clusterId,
                        spec,
                    },
                    opts,
                    {}
                );
            };
        case ServiceMethod.CREATE_JOB_INSTANCE:
            return async (data: any, opts: any) => {
                return debugModal(
                    {
                        ...data,
                        job: {
                            ...data.job,
                            job_spec: JSON.parse(data.job.job_spec),
                        },
                    },
                    opts,
                    {
                        job: new CcJob({
                            ...data.job,
                            created: new Date().toISOString(),
                        }),
                    }
                );
            };
        case ServiceMethod.SET_CONFIG:
            return async (data: any, opts: any) => {
                return debugModal(data, opts, {});
            };
        case ServiceMethod.CHECK_HOSTS:
            return async (data: any, opts: any) => {
                return debugModal(data, opts, {
                    checked_hosts: [
                        {
                            hardware: {
                                network_interfaces: {
                                    eth0: '192.168.1.113',
                                    eth1: '192.168.1.114',
                                },
                                cpu: {
                                    class_name: 'CmonCpuSet',
                                    cpus: [
                                        {
                                            cores: 4,
                                            cpu_max_ghz: 2.268,
                                            id: 0,
                                            model:
                                                'Intel(R) Xeon(R) CPU L5520 @ 2.27GHz',
                                            siblings: 8,
                                            vendor: 'GenuineIntel',
                                        },
                                        {
                                            cores: 4,
                                            cpu_max_ghz: 2.268,
                                            id: 1,
                                            model:
                                                'Intel(R) Xeon(R) CPU L5520 @ 2.27GHz',
                                            siblings: 8,
                                            vendor: 'GenuineIntel',
                                        },
                                    ],
                                    total_cores: 8,
                                    total_cpus: 2,
                                    total_siblings: 16,
                                },
                                memory: {
                                    class_name: 'CmonMemoryInfo',
                                    memory_available_mb: 16085,
                                    memory_free_mb: 16085,
                                    memory_total_mb: 16384,
                                    swap_free_mb: 0,
                                    swap_total_mb: 0,
                                },
                                mounted_partitions: [
                                    {
                                        device: '/dev/mapper/core1--vg-root',
                                        filesystem: 'ext4',
                                        free_gb: 142.631,
                                        free_mb: 146053,
                                        mountpoint: '/',
                                        total_gb: 162.286,
                                        total_mb: 166180,
                                    },
                                ],
                            },
                            host: {
                                class_name: 'CmonHost',
                                hostname: 'c1_node_hostname',
                                port: 8089,
                            },
                            status: {
                                error_code: 'HostIsOk',
                            },
                        },
                    ],
                });
            };
        case ServiceMethod.GET_SNAPSHOT_REPOSITORIES:
            return async () =>
                ({
                    total: 1,
                    snapshot_repositories: {
                        'cool-repo-name': {
                            type: 'fs',
                            settings: {
                                location:
                                    '/mnt/data/backups/es-snapshot-repositories',
                            },
                        },
                    },
                } as any);
        case ServiceMethod.GET_JOB_INSTANCES:
            return async ({ show_scheduled }: any) => {
                if (show_scheduled) {
                    return {
                        ...getJobInstances_schedules,
                        jobs: getJobInstances_schedules.jobs.map(
                            (props) => new CcJob(props as any)
                        ),
                    };
                }
                return {
                    ...getJobInstances,
                    jobs: getJobInstances.jobs.map(
                        (props) => new CcJob(props as any)
                    ),
                };
            };
        case ServiceMethod.GET_JOB_INSTANCE:
            return async ({ job_id }: any) => ({
                job: getJobInstances.jobs
                    .map((props) => new CcJob(props as any))
                    .find((job) => job.jobId === job_id),
            });
        case ServiceMethod.GET_ALL_CLUSTER_INFO:
            return async () => ({
                license: new CcLicense(getAllClusterInfo.license as any),
                license_check: new CcLicenseCheck(
                    getAllClusterInfo.license_check as any
                ),
                clusters: [...getAllClusterInfo.clusters].map(
                    (props) => new CcCluster(props as any)
                ),
            });
        case ServiceMethod.GET_CONFIG:
            return async () => Promise.resolve(getConfig1);
        case ServiceMethod.CLOUD_LIST_CREDENTIALS:
            return async () => Promise.resolve(listCredentials1);
        case ServiceMethod.CLOUD_PROXY:
            return async ({ method, body }: any) => {
                if (method === 'POST') {
                    if (body?.bucket === 'testbucket') {
                        return Promise.resolve({
                            error: 'testbucket is not valid name',
                        });
                    }
                    return Promise.resolve({ created: 1 });
                }
                return Promise.resolve(proxyBucketList1);
            };
        case ServiceMethod.EXECUTE_SCRIPT:
            return async ({ filename }: any) => {
                switch (filename) {
                    case '/s9s/mysql/widgets/schema/show_dbs.js':
                        return Promise.resolve(show_dbs);
                    case '/s9s/mysql/widgets/schema/show_tables.js':
                        return Promise.resolve(show_tables);
                }
            };
        case ServiceMethod.USER_GET_GROUPS:
            return async () => getGroupsResult;
        case ServiceMethod.USER_GET_USERS:
            return async () => getUsersResult;
        case ServiceMethod.USER_CREATE:
        case ServiceMethod.GROUP_CREATE:
        case ServiceMethod.ADD_TO_GROUP:
        case ServiceMethod.ADD_ACL:
        case ServiceMethod.SET_LDAP_CONFIG:
            return async (data: any, opts: any) => {
                return debugModal(data, opts, {});
            };

        case ServiceMethod.GET_TREE:
            return async () => getTreeResult;

        case ServiceMethod.WHO_AM_I:
            return async () => whoAmI;
        case ServiceMethod.GET_CONFIG_GROUPED:
            return async () => Promise.resolve(getConfigGrouped1);
        case ServiceMethod.LIST_ERROR_REPORTS:
            return async () => {
                return Promise.resolve(listErrorReports);
            };
        case ServiceMethod.REMOVE_ERROR_REPORT:
            return async (data: any) => {
                return debugModal(data, {}, {});
            };
        case ServiceMethod.GET_LDAP_CONFIG:
            return async () => getLdapConfigResult;
        case ServiceMethod.GET_MAINTENANCE:
            return async () => getMaintenance;
        case ServiceMethod.GET_DB_VERSIONS:
            return async ({
                cluster_type,
                vendor,
                patch_number,
            }: GetDbVersionsRequestData) => {
                if (vendor === 'mariadb') {
                    if (patch_number) {
                        return GetDbVersions_mariadb_patch;
                    }
                    return GetDbVersions_mariadb;
                }
                if (vendor === 'percona') {
                    if (patch_number) {
                        return GetDbVersions_mysql_percona_patch;
                    }
                    return GetDbVersions_mysql_percona;
                }
                return {
                    db_versions: [],
                };
            };
    }
}

function getMethod(method: ServiceMethod): Function | undefined {
    const [className, methodName] = method.split('.');
    return classes[className]?.[methodName];
}

function setMethod(method: ServiceMethod, value: Function) {
    const [className, methodName] = method.split('.');
    if (classes[className]?.[methodName]) {
        classes[className][methodName] = value;
    }
}

export function debugModal(data: any, opts: any, resolveValue: any) {
    return new Promise((resolve, reject) => {
        const modalInstance = notifyModal({
            title: 'Request to be sent',
            content: (
                <div>
                    <pre data-test-id="debug-modal-job-data">
                        {JSON.stringify(data, undefined, 2)}
                    </pre>
                    <pre>{JSON.stringify(opts)}</pre>
                </div>
            ),
            okText: 'Simulate success',
            cancelText: 'Simulate error',
            closable: true,
            width: 1000,
            renderFooter: () => (
                <FormFooter>
                    <Button
                        onClick={() => {
                            reject(new Error('error in request'));
                            modalInstance.destroy();
                        }}
                    >
                        Simulate error
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => {
                            resolve(resolveValue || {});
                            modalInstance.destroy();
                        }}
                    >
                        Simulate success
                    </Button>
                </FormFooter>
            ),
        });
    });
}
