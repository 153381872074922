import React, { useRef, useState } from 'react';
import ReactJson from 'react-json-view';
import { ButtonProps } from 'antd/lib/button';
import CcJob from '../../services/models/CcJob';
import { getStatusFormatIconFilled } from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import TextFormat from '@severalnines/bar-frontend-components/build/lib/Format/TextFormat';
import SpaceDescriptions from '../../common/Layout/SpaceDescriptions';
import DetailsModal, {
    DetailsModalApi,
} from '../../common/Feedback/DetailsModal';
import { getJobStatusFormatType } from './JobStatusFormat';
import JobLog from './JobLog';
import CmonJobMessage from '../../services/cmon/models/CmonJobMessage';
import TypographyText from '../../common/TypographyText';
import { CopyOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import JobActionsMenu from './JobActionsMenu';
import ButtonWithForm from '../../common/General/ButtonWithForm';
import AppSpin from '../../common/General/AppSpin';
import SpaceWide from '../../common/SpaceWide';
import AppActionButton from '../../common/General/AppActionButton';
import './JobDetailsButton.less';

export default JobDetailsButton;

export type JobDetailsButtonProps = ButtonProps & {
    job: CcJob;
    children?: React.ReactNode;
};

function JobDetailsButton({
    job,
    onClick,
    children,
    ...rest
}: JobDetailsButtonProps) {
    const [messagesStr, setMessagesStr] = useState<string>('');
    const modalRef = useRef<DetailsModalApi>();
    const handleClick = async (e: any) => {
        onClick?.(e);
        modalRef.current?.show();
    };

    const handleMessagesUpdate = (messages: CmonJobMessage[]) => {
        // using a div to remove html tags
        const div = document.createElement('div');
        div.innerHTML = messages
            .map(
                (m) =>
                    `${
                        m.created
                            ? new Date(m.created).toISOString()
                            : '                   '
                    }: ${m.messageText}`
            )
            .join('\n');
        setMessagesStr(
            `${JSON.stringify(job.spec, null, 2)}\n\n` +
                (div.textContent || div.innerText || '')
        );
    };

    return (
        <ButtonWithForm
            button={
                <AppActionButton onClick={handleClick} {...rest}>
                    {children || 'Details'}
                </AppActionButton>
            }
            form={
                <DetailsModal
                    ref={modalRef}
                    title="Job details"
                    headerTitle={
                        <TextFormat type={getJobStatusFormatType(job.status)}>
                            {job.title}
                        </TextFormat>
                    }
                    getContainer={() => document.body}
                    icon={getStatusFormatIconFilled(
                        getJobStatusFormatType(job.status)
                    )}
                    header={
                        <SpaceDescriptions>
                            <SpaceDescriptions.Item direction="vertical">
                                <div className="JobDetailsButton_json_viewer">
                                    <ReactJson
                                        src={job.spec}
                                        displayDataTypes={false}
                                        displayObjectSize={false}
                                        name="jobSpec"
                                        shouldCollapse={(field) =>
                                            field.name === 'jobSpec'
                                        }
                                    />
                                </div>
                            </SpaceDescriptions.Item>
                        </SpaceDescriptions>
                    }
                    headerExtra={
                        <JobActionsMenu job={job} excludeKeys={['details']} />
                    }
                    visible={true}
                >
                    <SpaceDescriptions
                        direction="vertical"
                        title={
                            <Space>
                                <span>Job messages</span>
                                {job.isRunning() ? (
                                    <AppSpin spinning={true} size="small" />
                                ) : null}
                            </Space>
                        }
                        extra={
                            <TypographyText
                                copyable={{
                                    text: messagesStr,
                                    icon: (
                                        <Space>
                                            <CopyOutlined />
                                            Copy to clipboard
                                        </Space>
                                    ),
                                }}
                            ></TypographyText>
                        }
                    >
                        <SpaceWide direction="vertical">
                            {job.jobId ? (
                                <JobLog
                                    jobId={job.jobId}
                                    onUpdate={handleMessagesUpdate}
                                />
                            ) : null}
                        </SpaceWide>
                    </SpaceDescriptions>
                </DetailsModal>
            }
        />
    );
}
