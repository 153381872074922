import React, { useMemo } from 'react';
import { Form } from 'antd';
import SshCheckableNodesAutocomplete from '../../../common/DataEntry/SshCheckeableNodesAutocomplete';
import { FormInstance, FormItemProps } from 'antd/lib/form';
import CcCluster from '../../../services/models/CcCluster';
import { CcNodeType } from '../../../services/models/CcNode';
import { TopologyItem } from '../../Topology/TopologyItem';
import { getNodeHostWithDesc } from '../../Nodes/NodeFormat';

export default PgBouncerHostnameField;
export type PgBouncerHostnameFieldProps = FormItemProps & {
    form: FormInstance;
    cluster: CcCluster;
    nodeTypes?: CcNodeType[];
};

function PgBouncerHostnameField({
    name,
    cluster,
    form,
    nodeTypes,
    ...rest
}: PgBouncerHostnameFieldProps) {
    const hostnames: TopologyItem[] = name
        ? form.getFieldValue(name) || []
        : [];

    const hostnameSnapshot = hostnames
        .map((item) => item.extraData.hostname)
        .filter((hostname: string) => !!hostname)
        .join(',');

    const options = useMemo(
        () =>
            cluster?.nodes
                .filter((node) => {
                    if (
                        cluster
                            .getNodesByType(CcNodeType.PGBOUNCER)
                            .some((host) => {
                                return (
                                    `${host.hostname}:${host.port}` ===
                                    `${node.hostname}:${node.port}`
                                );
                            })
                    ) {
                        return false;
                    }

                    if (
                        hostnames &&
                        hostnames.some((hostname) => {
                            return (
                                `${hostname.extraData.hostname}:${hostname.extraData.selectedOption?.port}` ===
                                `${node.hostname}:${node.port}`
                            );
                        })
                    ) {
                        return false;
                    }

                    return (
                        node.getType() === CcNodeType.POSTGRESQL ||
                        node.getType() === CcNodeType.TIMESCALEDB
                    );
                })
                .map((node) => ({
                    key: `${node.hostname} + ${node.port}`,
                    value: `${node.hostname}`,
                    label: getNodeHostWithDesc(node, false),
                    port: `${node.port}`,
                })),
        [hostnameSnapshot]
    );
    return (
        <Form.Item
            name={name}
            label="PgBouncer Node"
            rules={[
                {
                    required: true,
                    message: 'Please select hostname.',
                },
            ]}
            {...rest}
        >
            <SshCheckableNodesAutocomplete
                clusterId={cluster?.clusterId}
                emptyState={null}
                onlyPrimaries={true}
                formProps={{
                    primaryTitle: null,
                    primaryFormItemProps: {
                        withLessMarginBottom: true,
                    },
                }}
                checkSelectedHost
                autocompleteOptions={options}
            />
        </Form.Item>
    );
}
