import React, { useMemo } from 'react';
import { Col, Form, Input, Row, Space } from 'antd';
import FormItemInlineSwitch from '../../../common/DataEntry/FormItemInlineSwitch';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import { FormInstance } from 'antd/es';
import InputNumberWide from '../../../common/DataEntry/InputNumberWide';
import BackupCloudCredentialsForm from './BackupCloudCredentialsForm';
import CcCloudCredentials from '../../../services/models/CcCloudCredentials';
import { BackupFormFieldsType } from '../Config/BackupFormConfigurator';

export default BackupCloudForm;

export type BackupCloudFormProps = {
    form: FormInstance<BackupFormFieldsType>;
    fullWidth?: boolean;
    onLoadingChange?: (loading: boolean) => void;
};

function BackupCloudForm({
    form,
    fullWidth,
    onLoadingChange,
}: BackupCloudFormProps) {
    const handleCredentialsSelect = (credentials?: CcCloudCredentials) => {
        form.setFieldsValue({
            cloudProvider: credentials?.provider,
        });
    };

    const defaultRetention = useMemo(
        () => form.getFieldValue('cloudDefaultRetention'),
        [form]
    );
    return (
        <div className="BackupCloudForm">
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <h3>Cloud Storage</h3>
                </Col>
            </Row>
            <BackupCloudCredentialsForm
                form={form}
                fullWidth={fullWidth}
                onCredentialsSelect={handleCredentialsSelect}
                onLoadingChange={onLoadingChange}
            />
            <Row gutter={[24, 0]}>
                <Col xs={24} sm={24} md={fullWidth ? 24 : 12}>
                    <FormItemInlineSwitch
                        justify={true}
                        name="cloudEnableRetention"
                        label="Retention period"
                        valuePropName="checked"
                        extraOnSwitch={
                            <Form.Item
                                name="cloudCustomRetention"
                                extra={`The default retention period is ${defaultRetention} days`}
                            >
                                <InputNumberWide
                                    placeholder={`${defaultRetention} Days`}
                                    min={1}
                                />
                            </Form.Item>
                        }
                    />
                </Col>
                <Col xs={24} sm={24} md={fullWidth ? 24 : 12}>
                    <FormItemInlineSwitch
                        justify={true}
                        name="deleteAfterUpload"
                        label={
                            <Space>
                                <span>Delete after upload</span>
                                <InfoIcon
                                    info={
                                        <span>
                                            Delete local backup copy after it
                                            has uploaded to cloud
                                        </span>
                                    }
                                />
                            </Space>
                        }
                        valuePropName="checked"
                    />
                </Col>
            </Row>
            <Form.Item name="cloudProvider" hidden={true}>
                <Input type="hidden" />
            </Form.Item>
        </div>
    );
}

export function getBackupCloudValidate() {
    return ['cloudBucket', 'cloudCredentials'];
}
