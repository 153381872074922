import React from 'react';
import { FormInstance } from 'antd/lib/form';
import SpaceDescriptions from '../../../../common/Layout/SpaceDescriptions';
import YesNoFormat from '../../../../common/Format/YesNoFormat';
import AppDivider from '../../../../common/AppDivider';
import BackupFormat from '../../../Backups/BackupFormat';
import { AddNodeFormWizardFields } from './AddNodeFormWizard';
import TopologyItemSummary from '../../../../common/Form/Summary/TopologyItemSummary';
import { CcClusterType } from '../../../../services/models/CcCluster';

export default AddNodeSummary;

export type AddNodeSummaryFormProps = {
    form: FormInstance<AddNodeFormWizardFields>;
    hasSecurity?: boolean;
    hasAdvanced?: boolean;
    clusterType?: CcClusterType;
};

function AddNodeSummary({
    form,
    hasSecurity = true,
    hasAdvanced = true,
    clusterType,
}: AddNodeSummaryFormProps) {
    const formFields = form.getFieldsValue(true);
    return (
        <div className="AddNodeSummary">
            <SpaceDescriptions
                direction="vertical"
                title="Node configuration"
                size="small"
                alignItems="right"
            >
                {formFields.netcatPorts && (
                    <SpaceDescriptions.Item label="Netcat port" labelStrong>
                        {formFields.netcatPorts}
                    </SpaceDescriptions.Item>
                )}
                {formFields.nodePort ? (
                    <SpaceDescriptions.Item label="Port" labelStrong>
                        {formFields.nodePort}
                    </SpaceDescriptions.Item>
                ) : null}
                {formFields.redisSentinelPort ? (
                    <SpaceDescriptions.Item
                        label="Redis sentinel port"
                        labelStrong
                    >
                        {formFields.redisSentinelPort}
                    </SpaceDescriptions.Item>
                ) : null}

                {formFields.installSoftware !== undefined && (
                    <SpaceDescriptions.Item
                        label="Install software"
                        labelStrong
                    >
                        <YesNoFormat booleanVar={formFields.installSoftware} />
                    </SpaceDescriptions.Item>
                )}
                {formFields.usePackageForDataDir !== undefined && (
                    <SpaceDescriptions.Item
                        label="Use package default for datadir"
                        labelStrong
                    >
                        <YesNoFormat
                            booleanVar={formFields.usePackageForDataDir}
                        />
                    </SpaceDescriptions.Item>
                )}
                {formFields.dataDir && (
                    <SpaceDescriptions.Item label="Data directory" labelStrong>
                        {formFields.dataDir}
                    </SpaceDescriptions.Item>
                )}
                {formFields.customLogFile && (
                    <SpaceDescriptions.Item label="Logfile path" labelStrong>
                        {formFields.customLogFile}
                    </SpaceDescriptions.Item>
                )}
                {formFields.configurationTemplate && (
                    <SpaceDescriptions.Item label="Configuration template" labelStrong>
                        {formFields.configurationTemplate}
                    </SpaceDescriptions.Item>
                )}
            </SpaceDescriptions>
            <AppDivider />
            {hasSecurity && (
                <SpaceDescriptions
                    direction="vertical"
                    title="Security configuration"
                    size="small"
                    alignItems="right"
                >
                    <SpaceDescriptions.Item
                        label="Disable firewall"
                        labelStrong
                    >
                        <YesNoFormat booleanVar={formFields.disableFirewall} />
                    </SpaceDescriptions.Item>
                    <SpaceDescriptions.Item
                        label="Disable SELinux/AppArmor"
                        labelStrong
                    >
                        <YesNoFormat booleanVar={formFields.disableSeLinux} />
                    </SpaceDescriptions.Item>
                    <AppDivider />
                </SpaceDescriptions>
            )}
            {hasAdvanced && (
                <SpaceDescriptions
                    direction="vertical"
                    title="Advanced settings"
                    size="small"
                    alignItems="right"
                >
                    {formFields.delayReplica && (
                        <SpaceDescriptions.Item
                            label="Delay the replica node"
                            labelStrong
                        >
                            {formFields.delayReplicaSeconds}
                        </SpaceDescriptions.Item>
                    )}
                    {formFields.rebuildFromBackup && formFields.backup && (
                        <SpaceDescriptions.Item
                            label="Rebuild from backup"
                            labelStrong
                        >
                            <BackupFormat
                                backup={formFields.backup}
                                shortBackupName
                            />
                        </SpaceDescriptions.Item>
                    )}
                    {formFields.includeLB !== undefined && (
                        <SpaceDescriptions.Item
                            label="Include in LoadBalancer set (if exists)"
                            labelStrong
                        >
                            <YesNoFormat booleanVar={formFields.includeLB} />
                        </SpaceDescriptions.Item>
                    )}
                    {formFields.semiSynchronous !== undefined && (
                        <SpaceDescriptions.Item
                            label="Semi-synchronous replication"
                            labelStrong
                        >
                            <YesNoFormat
                                booleanVar={formFields.semiSynchronous}
                            />
                        </SpaceDescriptions.Item>
                    )}
                    {formFields.synchronous !== undefined && (
                        <SpaceDescriptions.Item
                            label="Synchronous replication"
                            labelStrong
                        >
                            <YesNoFormat
                                booleanVar={formFields.synchronous}
                            />
                        </SpaceDescriptions.Item>
                    )}
                    {formFields.clusterName && (
                        <SpaceDescriptions.Item
                            label="Instance name"
                            labelStrong
                        >
                            {formFields.clusterName}
                        </SpaceDescriptions.Item>
                    )}
                    {formFields.version && (
                        <SpaceDescriptions.Item label="Version" labelStrong>
                            {formFields.version}
                        </SpaceDescriptions.Item>
                    )}
                    <AppDivider />
                </SpaceDescriptions>
            )}
            <SpaceDescriptions
                direction="vertical"
                title="Node"
                size="small"
                alignItems="right"
            >
                {formFields.nodePrimary && (
                    <SpaceDescriptions.Item label="Primary node" labelStrong>
                        {formFields.nodePrimary}
                    </SpaceDescriptions.Item>
                )}
                {formFields.topology?.[0] && (
                    <TopologyItemSummary
                        item={formFields.topology?.[0]}
                        nodeProps={formFields}
                        labelStrong={true}
                        clusterType={clusterType}
                    />
                )}
            </SpaceDescriptions>
        </div>
    );
}
