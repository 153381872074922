import WizardConfiguration from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardConfiguration';
import React, { useMemo } from 'react';
import CcCluster, {
    CcClusterTechnology,
} from '../../../services/models/CcCluster';
import ClusterNodesUpgradeForm from './ClusterNodesUpgradeForm';
import { UpCircleOutlined } from '@ant-design/icons';
import WizardSelectCardStep from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardSelectCardStep';
import WizardConfigurationModal, {
    WizardConfigurationModalOption,
} from '../../../common/Navigation/WizardConfigurationModal';
import ClusterInPlaceUpgradeFrom from './ClusterInPlaceUpgradeFrom';
import { getDeploymentConfigurator } from '../../Services/Cluster/Deploy/DeploymentWizard';

export enum UpgradeWizardOptionType {
    MINOR = 'Minor',
    MAJOR = 'Major',
}

export type ClusterNodesUpgradeWizardProps = {
    cluster: CcCluster;
    onSuccess?: (option: WizardConfigurationModalOption | undefined) => void;
    onCancel?: () => void;
};
export default function ClusterNodesUpgradeWizard({
    cluster,
    onSuccess,
    onCancel,
}: ClusterNodesUpgradeWizardProps) {
    const canUpgrade = () => {
        return cluster.isTechnology(CcClusterTechnology.TECHNOLOGY_MYSQL) ||
            cluster.isTechnology(CcClusterTechnology.TECHNOLOGY_POSTGRESQL) ||
            cluster.isTechnology(CcClusterTechnology.TECHNOLOGY_MONGODB)
            ? true
            : false;
    };

    const canMajorUpgrade = () => {
        return cluster.isTechnology(CcClusterTechnology.TECHNOLOGY_POSTGRESQL)
            ? true
            : false;
    };

    const checkVersion = () => {
        const configurator = getDeploymentConfigurator(cluster?.clusterType);
        const vendorsArray = configurator.getVendors();
        const versions = vendorsArray[0].versions;
        const lastVersion = versions[0].value;
        if (cluster?.version) {
            return cluster?.version < lastVersion ? true : false;
        }
    };

    const options: WizardConfigurationModalOption<
        UpgradeWizardOptionType
    >[] = useMemo(() => {
        const items: WizardConfigurationModalOption<
            UpgradeWizardOptionType
        >[] = [
            {
                key: UpgradeWizardOptionType.MINOR,
                item: (
                    <WizardSelectCardStep.Item
                        key={UpgradeWizardOptionType.MINOR}
                        icon={<UpCircleOutlined />}
                        title="Minor Upgrades"
                        action={UpgradeWizardOptionType.MINOR}
                        disabled={!canUpgrade()}
                        description="Performs minor software upgrades for database and load balancer software, for example from  15.x to 15.y in a rolling upgrade fashion. 
                        ClusterControl will perform the software upgrade based on what is available on the package repository for the particular vendor."
                        descriptionSecondary={
                            !canUpgrade()
                                ? 'This feature is not available right now.'
                                : ''
                        }
                        buttonTitle={getActionTypeTitle(
                            UpgradeWizardOptionType.MINOR
                        )}
                    />
                ),
                step: {
                    step: UpgradeWizardOptionType.MINOR,
                    title: 'Minor Upgrade',
                    content: <ClusterNodesUpgradeForm cluster={cluster} />,
                },
            },
            ...(canMajorUpgrade()
                ? [
                      {
                          key: UpgradeWizardOptionType.MAJOR,
                          item: (
                              <WizardSelectCardStep.Item
                                  key={UpgradeWizardOptionType.MAJOR}
                                  icon={<UpCircleOutlined />}
                                  title="Major Upgrades"
                                  action={UpgradeWizardOptionType.MAJOR}
                                  description="Performs an upgrade to a later major version for the database software and data files. Depending on the upgrade method you might require the storage capacity to hold 2 data sets."
                                  descriptionSecondary={
                                      !canMajorUpgrade()
                                          ? 'This feature is not available right now.'
                                          : !checkVersion()
                                          ? 'Already up to date'
                                          : ''
                                  }
                                  disabled={
                                      canMajorUpgrade() && !checkVersion()
                                  }
                                  buttonTitle={getActionTypeTitle(
                                      UpgradeWizardOptionType.MAJOR
                                  )}
                              />
                          ),
                          step: {
                              step: UpgradeWizardOptionType.MAJOR,
                              title: `Major: ${cluster.clusterName} (ID:${cluster.clusterId})`,
                              content: (
                                  <ClusterInPlaceUpgradeFrom
                                      cluster={cluster}
                                  />
                              ),
                          },
                      },
                  ]
                : []),
        ];

        return items;
    }, []);

    return (
        <WizardConfigurationModal
            title="Upgrades"
            description="Perform major or minor node upgrades."
            options={options}
            onSuccess={onSuccess}
            onCancel={onCancel}
            modalProps={
                {
                    className: 'ClusterNodesUpgradeWizard',
                } as any
            }
        />
    );
}

export function getActionTypeTitle(type: UpgradeWizardOptionType) {
    switch (type) {
        case UpgradeWizardOptionType.MINOR:
            return 'Minor';
        case UpgradeWizardOptionType.MAJOR:
            return 'Major';
    }
}
